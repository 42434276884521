import { useState } from 'react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useOrganizationSites } from 'orgs-sites/org/api'
import { Icon, Text } from 'src/components/ui'
import { useNavigate } from 'react-router-dom'

type Props = {
  organization: any
  closeDropdown: () => void
}

export function FactoryDropdown({
  organization,
  closeDropdown,
}: Props): JSX.Element {
  const [showMenu, setShowMenu] = useState(false)
  const { data: orgSites } = useOrganizationSites({ orgId: organization.id })

  const navigate = useNavigate()

  return (
    <div>
      <div
        className="flex cursor-pointer items-center justify-between p-xs"
        onClick={() => setShowMenu(!showMenu)}
      >
        <Text variant="content">{organization.name}</Text>
        <Icon
          icon={light('chevron-down')}
          className={`cursor-pointer ${
            showMenu ? 'rotate-180' : 'rotate-0'
          } transition duration-200`}
        />
      </div>
      {showMenu && (
        <>
          {orgSites?.map((site: any, index: number) => (
            <div
              key={site.id}
              onClick={() => {
                navigate(`/settings/orgs/${organization.id}/sites/${site.id}`)
                closeDropdown()
              }}
              className="bg-background-disabled-light px-l py-xs"
              style={{
                borderBottom:
                  index !== orgSites.length - 1
                    ? '0.5px solid #d9d9d9'
                    : 'none',
              }}
            >
              <Text variant="description" bold>
                {site.name}
              </Text>
            </div>
          ))}
        </>
      )}
    </div>
  )
}

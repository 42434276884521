import { IRowNode } from '@ag-grid-community/core'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Icon } from 'src/components/ui'
import { ErrorNode } from '../../opc-ua-connection.types'
import { constructRoute } from '../opcUaBrowse.utils'

export function LoadingErrorRenderer({
  node,
  failedNodes,
  refreshCache,
}: {
  node: IRowNode
  failedNodes: ErrorNode[]
  refreshCache: (node: IRowNode) => void
}): JSX.Element {
  const route = constructRoute(node)
  const failedNode = failedNodes.find(
    errNode => errNode.id === node.parent?.key,
  )
  return (
    <div
      className="mx-[26px] flex w-full items-center justify-between"
      style={{ marginLeft: (route.length - 1) * 28 + 17 }}
    >
      <div className="flex items-center gap-xs">
        <Icon
          size="regular"
          icon={light('circle-exclamation')}
          className="text-text-danger"
        />
        Failed to load ({failedNode?.reason || 'Unknown error'})
      </div>
      <Icon
        icon={light('rotate-right')}
        className="cursor-pointer"
        size="regular"
        onClick={() => refreshCache(node.parent!)}
      />
    </div>
  )
}

import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Icon, Text, Spinner } from 'src/components/ui'
import { useAssets } from 'assets/api'
import { useTags } from 'tags/api'

export function AssetStatusCard(): JSX.Element {
  const { data: tags = [], isLoading: tagsIsPending } = useTags()
  const assetsQuery = useAssets()

  return (
    <>
      <div className="mb-xs grid grid-cols-2 items-center justify-items-center border-0 border-b border-solid border-border pb-2 text-text-tertiary">
        <div className="flex items-center gap-xs">
          <Icon className="w-[24px]" icon={light('sitemap')} />
          <Text variant="title" className="!font-roboto">
            Assets
          </Text>
        </div>
        <div>
          {assetsQuery.isLoading ? (
            <Spinner small inline />
          ) : (
            <p className="my-0 text-huge leading-1.5 text-icon-success-secondary">
              {assetsQuery.data?.length ?? 0}
            </p>
          )}
        </div>
      </div>
      <div className="grid grid-cols-2 items-center justify-items-center border-b border-border text-text-tertiary">
        <div className="flex items-center gap-xs">
          <Icon className="w-[24px]" icon={light('sensor-cloud')} />
          <Text variant="title" className="!font-roboto">
            Tags
          </Text>
        </div>
        <div>
          {tagsIsPending ? (
            <Spinner small inline />
          ) : (
            <p className="my-0 text-huge leading-1.5 text-icon-success-secondary">
              {tags.length}
            </p>
          )}
        </div>
      </div>
    </>
  )
}

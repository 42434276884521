import { useNavigate } from 'react-router-dom'
import { Button, Text } from 'src/components/ui'
import { useTitle } from 'src/utility'
import { useSite } from 'src/contexts/site'
import * as path from 'src/utility/path'
import { useStreamlitApps } from './labs.api'

export function LabsPage(): JSX.Element {
  useTitle('Labs')
  const navigate = useNavigate()
  const { id: factoryId, rootLink } = useSite()

  const streamlitAppsQuery = useStreamlitApps(factoryId)
  const streamlitApps = streamlitAppsQuery.data ?? []
  const apps = streamlitApps.sort((a, b) =>
    a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
  )

  const handleLaunch = (slug: string): void => {
    navigate(path.join(rootLink, 'labs', slug))
  }

  return (
    <div className="mx-s">
      <div className="flex flex-wrap gap-s">
        {apps.map(({ name, description, slug }) => (
          <div
            key={slug}
            className="flex w-[335px] flex-col gap-s rounded-2xs border-[0.5px] border-solid border-border bg-background p-s"
            onClick={() => handleLaunch(slug)}
          >
            <div className="flex flex-col gap-2xs">
              <Text variant="content" bold>
                {name}
              </Text>
              <Text variant="description">{description}</Text>
            </div>

            <div className="mt-auto flex">
              <Button
                variant={'primary'}
                title={'Launch'}
                onClick={() => handleLaunch(slug)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useDrag } from 'react-dnd'
import { useTrendDnd } from 'pages/site/trend/trend/trend.state'
import { Icon, Text, Tooltip, Spinner } from 'src/components/ui'
import { zIndex } from 'src/utility/constants/StyleConstants'
import { tsdMax, tsdMin } from 'src/utility/timeSeries'
import { ChartOptions, SeriesOptions } from 'src/types/chartTypes'
import { ChartData } from '../../useChartData'
import Color from './Color'
import GraphSettings from './GraphSettings'

// IMPORTANT!!
// All of the fields with text components are wrapped in a <span> instead
// of just being a fragment (<>) because of google translate
// See: https://github.com/facebook/react/issues/11538

interface TagProps {
  tagName?: string
  engUnit?: string
  displayName?: string
  description?: string
}

const tagUnit = (unit?: string): string => {
  if (unit && !['None', '-'].includes(unit)) {
    return `${unit}`
  }
  return 'unit is missing'
}

const TagInfo = (tag?: TagProps): JSX.Element => {
  if (tag) {
    const displayName = tag.displayName || tag.tagName
    return (
      <div className="grid w-full grid-cols-[1fr_1fr_80px] items-center gap-xs">
        <span title={tag.tagName} className="overflow-hidden whitespace-nowrap">
          <Text variant="description" bold>
            {displayName}
          </Text>
        </span>
        <span
          title={tag.description}
          className="overflow-hidden whitespace-nowrap"
        >
          <Text variant="description">{tag.description}</Text>
        </span>
        <span
          title={tag.engUnit}
          className="overflow-hidden whitespace-nowrap text-right"
        >
          <Text variant="description" bold>
            {tagUnit(tag.engUnit)}
          </Text>
        </span>
      </div>
    )
  }
  return <Text variant="content">unknown</Text>
}

const spinner = (
  <>
    {' '}
    <Spinner small inline />
  </>
)

const TagLegend = (props: TagProps): JSX.Element => {
  return props.tagName ? <TagInfo {...props} /> : spinner
}

interface ModelLegendProps {
  name?: string
  type?: {
    name?: string
  }
  tag?: TagProps
}

const ModelLegend = (props: ModelLegendProps): JSX.Element =>
  props.name ? (
    <div className="grid w-full grid-cols-[1fr_1fr_80px] items-center gap-xs">
      <span className="overflow-hidden whitespace-nowrap">
        <Text variant="description" bold>
          {props.type?.name} model: {props.name}
        </Text>
      </span>
      <span className="overflow-hidden whitespace-nowrap">
        <Text variant="description">
          {props.tag?.displayName ?? props.tag?.tagName}
        </Text>
      </span>
      <span className="overflow-hidden whitespace-nowrap text-right">
        <Text variant="description" bold>
          {tagUnit(props.tag?.engUnit)}
        </Text>
      </span>
    </div>
  ) : (
    spinner
  )

interface AnomalyScoreLegendProps {
  name?: string
  tag?: TagProps
}

const SpikeScoreLegend = ({
  name,
  tag,
}: AnomalyScoreLegendProps): JSX.Element =>
  name ? (
    <div className="grid w-full grid-cols-[1fr_1fr_80px] items-center gap-xs">
      <span className="overflow-hidden whitespace-nowrap">
        <Text variant="description" bold>
          Spike anomaly score for {name}
        </Text>
      </span>
      <span className="overflow-hidden whitespace-nowrap">
        <Text variant="description">{tag?.displayName ?? tag?.tagName}</Text>
      </span>
      <span className="overflow-hidden whitespace-nowrap text-right">
        <Text variant="description" bold>
          {tagUnit(tag?.engUnit)}
        </Text>
      </span>
    </div>
  ) : (
    spinner
  )

const ShortScoreLegend = ({
  name,
  tag,
}: AnomalyScoreLegendProps): JSX.Element =>
  name ? (
    <div className="grid w-full grid-cols-[1fr_1fr_80px] items-center gap-xs">
      <span className="overflow-hidden whitespace-nowrap">
        <Text variant="description" bold>
          Short anomaly score for {name}
        </Text>
      </span>
      <span className="overflow-hidden whitespace-nowrap">
        <Text variant="description">{tag?.displayName ?? tag?.tagName}</Text>
      </span>
      <span className="overflow-hidden whitespace-nowrap text-right">
        <Text variant="description" bold>
          {tagUnit(tag?.engUnit)}
        </Text>
      </span>
    </div>
  ) : (
    spinner
  )

type LegendRowProps = LegendProps & {
  index: number
  series: SeriesOptions
}

function LegendRow({
  chart,
  index,
  series,
  data,
  setOptions,
  remove,
  hoverIndex,
  setHoverIndex,
  isDataPending,
  isModal,
}: LegendRowProps): JSX.Element {
  const { setDragState } = useTrendDnd()
  const [{ isDragging }, dragRef] = useDrag(() => ({
    item: {
      series,
      sourceChartId: chart.id,
    },
    type: 'legend-row',
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag: () => !isModal,
  }))

  useEffect(() => {
    if (isDragging) {
      setDragState({
        isDragging: true,
      })
    } else {
      setDragState({ isDragging: false })
    }
  }, [chart.id, isDragging, series, setDragState])

  const Component = {
    tag: TagLegend,
    forecast: ModelLegend,
    prediction: ModelLegend,
    spikeScore: SpikeScoreLegend,
    shortScore: ShortScoreLegend,
  }[series.type]

  const warnings: string[] = []
  if (data[index]?.data?.length === 0) {
    warnings.push('No data available for this time range')
  }

  // We want this to triger when either (or both) are true:
  // - props.min is set and either min or value are below props.min
  // - props.max is set and either max or value are above props.max
  //
  // We check for undefined both in the `some` and in the `if` because
  // we don't want to process all of the data is there is no
  // min or max. And the fact that we've checked for undefined seems
  // to be forgotten within the `some` call.
  if (
    (series.min !== undefined || series.max !== undefined) &&
    data[index]?.data?.some(tsd => {
      const min = tsdMin(tsd)
      const max = tsdMax(tsd)

      return (
        (series.min !== undefined && min < series.min) ||
        (series.max !== undefined && max > series.max)
      )
    })
  ) {
    warnings.push('There is data outside the y-axis range.')
  }

  return (
    <div
      ref={dragRef}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className="flex items-center gap-2xs hover:bg-background-hover cursor-grab"
      onMouseEnter={() => setHoverIndex(index)}
      onMouseLeave={() => setHoverIndex(undefined)}
    >
      <Color
        hover={index === hoverIndex}
        index={series.colorIndex ?? index + chart.id}
        onClick={() => {
          if (setOptions) {
            setOptions(index, {
              inactive: series.inactive ? false : true,
            })
          }
        }}
        inactive={series.inactive}
      />
      <div
        className={classNames(
          'flex flex-1 select-text',
          !isModal && 'cursor-grab',
        )}
      >
        <Component {...data[index]} />
      </div>
      <div className="flex w-[50px] items-center justify-end gap-2xs">
        {!isDataPending && warnings.length > 0 && (
          <Tooltip
            zIndex={isModal ? zIndex.modalLegendMenu : zIndex.trendLegendMenu}
            render={() =>
              warnings.map(warning => <div key={warning}>{warning}</div>)
            }
          >
            <div style={{ lineHeight: 0 }}>
              <Icon
                icon={light('triangle-exclamation')}
                className="text-icon-warning"
              />
            </div>
          </Tooltip>
        )}
        {series.min !== undefined && (
          <Tooltip
            zIndex={isModal ? zIndex.modalLegendMenu : zIndex.trendLegendMenu}
            render={() =>
              `The y-axis has been locked to the range [${
                series.min ?? 'auto'
              }, ${series.max ?? 'auto'}]. You can revert this in the menu.`
            }
          >
            <div style={{ lineHeight: 0 }}>
              <Icon icon={light('lock')} className="text-icon-warning" />
            </div>
          </Tooltip>
        )}
        <Icon
          onClick={() => remove && remove(index)}
          icon={light('circle-xmark')}
          className="ml-2xs cursor-pointer text-text-danger"
        />
      </div>
    </div>
  )
}

interface LegendProps {
  chart: ChartOptions
  setChart?: (options: ChartOptions) => void
  data: ChartData[]
  setOptions?: (index: number, options: Partial<SeriesOptions>) => void
  remove?: (index: number) => void
  hoverIndex: any
  setHoverIndex: any
  isDataPending: any
  isModal?: boolean
}

type ScrollIndicator = {
  top: boolean
  bottom: boolean
}

export function Legend(props: LegendProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null)
  const { chart, data, setOptions, setChart, isModal } = props
  const [scrollIndicator, setScrollIndicator] = useState<ScrollIndicator>({
    top: false,
    bottom: false,
  })

  const checkScrollIndicators = (): void => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current
      // Show scroll indicator if we have scroll
      // safari for some reason has a 2px difference so we subtract 2
      if (clientHeight < scrollHeight - 2) {
        const contentAtBottom = scrollHeight - clientHeight - scrollTop
        if (contentAtBottom > 0 && scrollTop > 0) {
          setScrollIndicator({ top: true, bottom: true })
          return
        }
        if (contentAtBottom > 0) {
          setScrollIndicator({ top: false, bottom: true })
          return
        }
        if (scrollTop > 0) {
          setScrollIndicator({ top: true, bottom: false })
          return
        }
      } else {
        setScrollIndicator({ top: false, bottom: false })
      }
    }
  }

  useEffect(() => {
    checkScrollIndicators()
    const container = containerRef.current
    if (container) {
      container.addEventListener('scroll', checkScrollIndicators)
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', checkScrollIndicators)
      }
    }
  }, [containerRef, chart.data.length])

  return (
    <div className="flex cursor-default flex-col gap-xs">
      <GraphSettings
        data={data}
        chart={chart}
        setOptions={setOptions}
        setChart={setChart}
        isModal={isModal}
      />
      <div
        className={classNames(
          'overflow-hidden',
          scrollIndicator.top && scrollIndicator.bottom
            ? 'bg-combined-gradient'
            : scrollIndicator.top
            ? 'bg-top-gradient'
            : scrollIndicator.bottom && 'bg-bottom-gradient',
        )}
      >
        <div
          ref={containerRef}
          className={classNames(
            'relative flex max-h-[100px] flex-col gap-2xs overflow-y-auto px-xs pb-xs pt-3xs',
          )}
        >
          {chart.data.map((series, index) => (
            <LegendRow
              key={`${series.id}${index}`}
              index={index}
              series={series}
              {...props}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

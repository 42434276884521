import { isDefined } from 'src/types'
import {
  ChartAxis,
  ChartMarkerType,
  ChartOptions,
  ChartType,
} from 'src/types/chartTypes'
import * as z from 'zod'
import { nextId } from '.'

const seriesBaseSchema = z.object({
  min: z.number().optional(),
  max: z.number().optional(),
  colorIndex: z.number().optional(),
  inactive: z.boolean().optional(),
  disableRange: z.boolean().optional(),
  axis: z.nativeEnum(ChartAxis).optional(),
})

const seriesSchema = z.discriminatedUnion('type', [
  z
    .object({
      type: z.literal('forecast'),
      id: z.string(),
      offset: z.number(),
    })
    .merge(seriesBaseSchema),
  z
    .object({
      type: z.enum(['tag', 'prediction', 'spikeScore', 'shortScore']),
      id: z.string(),
    })
    .merge(seriesBaseSchema),
])

const markerSchema = z.discriminatedUnion('type', [
  z.object({
    id: z.string(),
    type: z.literal(ChartMarkerType.LINE),
    value: z.number(),
    color: z.string(),
    label: z.string().optional(),
  }),
  z.object({
    id: z.string(),
    type: z.literal(ChartMarkerType.RANGE),
    from: z.number(),
    to: z.number(),
    color: z.string(),
    label: z.string().optional(),
  }),
])

const chartSchema = z.object({
  // old ids were strings. Accept these, but ignore
  id: z.union([z.string(), z.number()]).optional(),
  data: z.array(seriesSchema).default([]),
  type: z.nativeEnum(ChartType).default(ChartType.TimeSeries),
  commonY: z.boolean().optional(),
  min: z.number().optional(),
  max: z.number().optional(),
  colorIndex: z.number().optional(),
  markers: z.array(markerSchema).optional(),
})

const chartsSchema = z.array(chartSchema)

export function parseCharts(param: string): ChartOptions[] {
  const raw = JSON.parse(param)
  const charts = chartsSchema.parse(raw)

  const ids = charts.map(c => {
    if (typeof c.id == 'number') {
      return c.id
    }
    return undefined
  })
  const defined = ids.filter(isDefined)
  const set = new Set(defined)

  if (set.size != defined.length) {
    // we have duplicates. Reset all to index
    return charts.map((chart, id) => {
      return {
        ...chart,
        id,
      }
    })
  }

  function next(): number {
    const id = nextId(set)
    set.add(id)
    return id
  }

  return charts.map((chart, index) => {
    const id = ids[index] ?? next()
    return {
      ...chart,
      id,
    }
  })
}

export function encodeCharts(charts: ChartOptions[]): string {
  const cs = charts.map(c => {
    return {
      ...c,
      // the default chart type is time-series, so we don't need it in the url
      type: c.type === ChartType.TimeSeries ? undefined : c.type,
    }
  })
  return JSON.stringify(cs)
}

import { useRef, useState } from 'react'
import { useClickOutside } from 'src/utility'
import { Text } from 'src/components/ui'
import { markerColorOptions } from 'src/types/chartTypes'
import { MarkerProps } from './Marker'
import { updateMarker } from 'src/contexts/charts'

export function MarkerColorSelector({
  chart,
  setChart,
  id,
}: MarkerProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)
  const marker = chart.markers?.find(m => m.id === id)

  useClickOutside(dropdownRef, () => {
    setIsOpen(false)
  })

  if (!marker) return <></>

  function handleMarkerColorChange(color: string): void {
    setChart(updateMarker(chart, id, m => ({ ...m, color })))
  }

  const markerColor = markerColorOptions.find(m => m.value === marker.color)
  if (!markerColor) return <></>

  return (
    <div
      className="relative flex cursor-pointer items-center gap-xs rounded-2xs border border-solid border-border bg-background p-2xs"
      onClick={() => setIsOpen(!isOpen)}
      ref={dropdownRef}
    >
      <Text variant="description" bold>
        {markerColor.label}
      </Text>
      <div
        className="size-s rounded-full"
        style={{ backgroundColor: marker.color }}
      />
      {isOpen && (
        <div className="absolute left-0 top-[calc(100%+4px)] z-20 rounded-2xs border border-solid border-border bg-background shadow-dropdown">
          {markerColorOptions.map(option => (
            <div
              key={option.value}
              onClick={() => handleMarkerColorChange(option.value)}
              className="flex cursor-pointer items-center gap-xs p-xs transition hover:bg-background-hover"
            >
              <div
                className="flex size-[12px] items-center justify-center rounded-full"
                style={{ backgroundColor: option.value }}
              >
                {marker.color === option.value && (
                  <div className="size-xs rounded-full border-2 border-solid border-white bg-transparent" />
                )}
              </div>
              <Text variant="description" bold className="whitespace-nowrap">
                {option.label}
              </Text>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

import { parseISO } from 'date-fns'
import {
  Forecast,
  ForecastDto,
  ForecastPrediction,
  ForecastValueDto,
} from './forecast.types'

export function mapApiToForecast(forecast: ForecastDto): Forecast {
  return {
    ...forecast,
    madeAt: parseISO(forecast.madeAt),
    prediction: forecast.prediction.map(mapApiToForecastPrediction),
  }
}

export function mapApiToForecastPrediction(
  data: ForecastValueDto,
): ForecastPrediction {
  return {
    ...data,
    timestamp: parseISO(data.timestamp),
  }
}

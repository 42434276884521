import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { Icon, Text, Tooltip } from 'src/components/ui'

type Props = {
  label: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  children: React.ReactNode
  disabled?: boolean
  tooltip?: string
}

export function SettingsAccordion({
  label,
  isOpen,
  setIsOpen,
  children,
  disabled,
  tooltip,
}: Props): JSX.Element {
  return (
    <Tooltip
      isOpen={!tooltip ? false : undefined}
      direction="bottom"
      render={() => <Text variant="description">{tooltip}</Text>}
    >
      <div
        className={classNames(
          'p-xs rounded-2xs border border-solid border-border',
          disabled && 'opacity-50',
        )}
      >
        <div
          className={classNames(
            'flex justify-between items-center select-none',
            isOpen && 'pb-xs border-0 border-b border-solid border-border',
            !disabled && 'cursor-pointer',
          )}
          onClick={() => {
            if (disabled) return
            setIsOpen(!isOpen)
          }}
        >
          <Text variant="description" bold>
            {label}
          </Text>
          <Icon
            icon={light('chevron-down')}
            className={classNames('transition', isOpen && 'rotate-180')}
          />
        </div>
        {isOpen && <div className="pt-xs">{children}</div>}
      </div>
    </Tooltip>
  )
}

import { OnlyModelProps } from 'src/types'
import { useSite } from 'src/contexts/site'
import { humanDate } from 'src/utility/time'
import {
  getTrainingStart,
  isRunning,
  NEW,
  NOT_RUNNING,
} from 'models/model/model.utils'
import { Banner, Text } from 'src/components/ui'
import { sub } from 'date-fns'
import {
  getModelTagAvailableTimeRanges,
  getModelTagsWithNoDataBeforeSixMonthsAgo,
  getModelTagsWithNoDataBeforeStart,
  getModelTagsWithNoDataInLastMonth,
} from '../../model.utils'

const dateInfo = (text: string, date: number | undefined): string => {
  if (date !== undefined) {
    return `(${text}: ${humanDate(date)})`
  }
  return '(no data)'
}

export function ConfigWarning({ model }: OnlyModelProps): JSX.Element {
  const trainingStart = getTrainingStart(model)
  const tagAvailableTimeRanges = getModelTagAvailableTimeRanges(model)

  const noDataBeforeStart = getModelTagsWithNoDataBeforeStart(model)

  const notSixMonthsOfData = getModelTagsWithNoDataBeforeSixMonthsAgo(model)
  const noDataInLastMonth = getModelTagsWithNoDataInLastMonth(model)

  // get the display name of the tag
  const tagSpan = (tagName: string): JSX.Element => {
    const name = tagAvailableTimeRanges[tagName]?.displayName || tagName
    return <span title={tagName}>{name}</span>
  }

  const { isWorkshop } = useSite()

  return (
    <>
      {model.state === NEW &&
        model.method.name === 'Nowcast' &&
        model.inputTags.includes(model.tag) && (
          <Banner variant="error" className="col-span-2">
            Anomaly models of type nowcast do not allow using the output as
            input. To fix this issue, either remove the output from the list of
            inputs or change the model type.
          </Banner>
        )}
      {model.state === NEW && noDataBeforeStart.length > 0 && (
        <Banner variant="warning" className="col-span-2">
          <div>
            <Text bold>
              This model contains tags that do not have data from the before the
              start of the training period ({humanDate(trainingStart)}). The
              following tags have this problem:
            </Text>
            <ul className="my-2xs pl-m">
              {noDataBeforeStart.map(tagName => (
                <li key={tagName}>
                  <Text>
                    {tagSpan(tagName)}{' '}
                    {dateInfo(
                      'first data point',
                      tagAvailableTimeRanges[tagName].min,
                    )}
                  </Text>
                </li>
              ))}
            </ul>
            <Text bold>
              To fix this issue, you can edit the training period or remove the
              problematic tags.
            </Text>
          </div>
        </Banner>
      )}
      {model.state === NEW && notSixMonthsOfData.length > 0 && (
        <Banner variant="warning" className="col-span-2">
          <div>
            <Text bold>
              This model contains tags that do not have data for the previous
              six months ({humanDate(sub(new Date(), { months: 6 }).valueOf())}{' '}
              - {humanDate(Date.now())}). This will cause model training to fail
              because predictions for the last six months are generated as part
              of training. The following tags have this problem:
            </Text>
            <ul className="my-2xs pl-m">
              {notSixMonthsOfData.map(tagName => (
                <li key={tagName}>
                  <Text>
                    {tagSpan(tagName)}{' '}
                    {dateInfo(
                      'first data point',
                      tagAvailableTimeRanges[tagName].min,
                    )}
                  </Text>
                </li>
              ))}
            </ul>
            <Text bold>
              To fix this issue, you can edit the training period or remove the
              problematic tags.
            </Text>
          </div>
        </Banner>
      )}
      {[NEW, NOT_RUNNING].includes(model.state) &&
        !isWorkshop &&
        noDataInLastMonth.length > 0 && (
          <Banner
            variant={model.state === NOT_RUNNING ? 'error' : 'warning'}
            className="col-span-2"
          >
            <div>
              <Text bold>
                Activating this model will fail as it contains tags without data
                for the last month. The following tags have this problem:
              </Text>
              <ul className="my-2xs pl-m">
                {noDataInLastMonth.map(tagName => (
                  <li key={tagName}>
                    <Text>
                      {tagSpan(tagName)}{' '}
                      {dateInfo(
                        'last registered data point',
                        tagAvailableTimeRanges[tagName].max,
                      )}
                    </Text>
                  </li>
                ))}
              </ul>
              {model.state === NEW && (
                <Text bold>
                  You may still be able to train the model, but new datapoints
                  must be registered to activate the model.
                </Text>
              )}
            </div>
          </Banner>
        )}
      {isRunning(model.state) && noDataInLastMonth.length > 0 && (
        <Banner variant="warning" className="col-span-2">
          <div>
            <Text bold>
              This model will most likely fail, as it contains tags without data
              for the last month. The following tags have this problem:
            </Text>
            <ul className="my-2xs pl-m">
              {noDataInLastMonth.map(tagName => (
                <li key={tagName}>
                  <Text>
                    {tagSpan(tagName)}{' '}
                    {dateInfo(
                      'last registered data point',
                      tagAvailableTimeRanges[tagName].max,
                    )}
                  </Text>
                </li>
              ))}
            </ul>
          </div>
        </Banner>
      )}
    </>
  )
}

import Highlighter from 'react-highlight-words'
import { Text, TableConfig } from 'src/components/ui'
import { TagSparkline } from './components'

interface Actions {
  renderAction: any
}

export function getTagInputListConfig({ renderAction }: Actions): TableConfig {
  return {
    pageSize: 5,
    allowPaging: true,
    columns: [
      {
        field: 'displayName',
        headerName: 'Tag',
        flex: 400,
        cellRenderer: ({
          data: { displayName, tagName, description, engUnit },
        }: any) => {
          const descriptionText =
            engUnit && engUnit.toLowerCase() !== 'none'
              ? `${description} (${engUnit})`
              : description
          return (
            <>
              <Text>
                <Highlighter
                  searchWords={[]}
                  textToHighlight={displayName}
                  autoEscape
                  title={tagName}
                />
              </Text>
              <Text className="text-text-tertiary">{descriptionText}</Text>
            </>
          )
        },
      },
      {
        field: 'parentName',
        headerName: 'Parent',
        flex: 120,
        cellRenderer: ({ value }: any) => <Text>{value}</Text>,
      },
      {
        field: 'label',
        headerName: 'Label',
        flex: 120,
        filterValueGetter: ({ data }: any) => data.label || 'Unlabeled',
        cellRenderer: ({ value }: any) => <Text>{value || 'Unlabeled'}</Text>,
      },
      {
        headerName: 'Last 60 Minutes',
        filter: '',
        cellRenderer: ({ data: { tagName } }: any) => (
          <TagSparkline id={tagName} />
        ),
        // colAllowFiltering: false,
        sortable: false,
        flex: 200,
      },
      {
        headerName: '',
        cellRenderer: renderAction,
        menuTabs: [],
        // colAllowFiltering: false,
        sortable: false,
      },
    ],
  }
}

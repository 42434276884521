import React from 'react'
import {
  hasRunInitialInference,
  hasRunAnomalyDetection,
  AWAITING_ANOMALY_ANALYSIS,
  NOT_RUNNING,
  ERROR,
  NEW,
  ARCHIVED,
  isRunning,
} from 'models/model/model.utils'
import { useFilter } from 'src/contexts/filter'
import { stringify } from 'src/conditions'
import { Banner } from 'src/components/ui'
import {
  useUpdateModelAnomalyConditions,
  useRunAnomalyDetectionMutation,
} from 'models/api'
import { FetchAnomalyModel, GqlAnomalyConditionsFragment } from 'src/services'
import { isEqual } from 'lodash'
import {
  ModelOverview,
  ModelActions,
  OutputTag,
  InputTags,
  ConfigWarning,
  NewModelInfo,
} from 'models/model/components'
import {
  AnomalyAnalysisInfo,
  AnomalyFilter,
  OutputTagAndPrediction,
  Anomalies,
  AnomalyScore,
  AnomalyThresholds,
} from './sections'
import { getAnomalyThresholds } from './anomaly-detection.utils'

interface AnomalyDetectProps {
  model: FetchAnomalyModel
}

export function AnomalyDetectionModel({
  model,
}: AnomalyDetectProps): JSX.Element {
  const { condition } = useFilter()
  const instantUpdate = model.state === AWAITING_ANOMALY_ANALYSIS
  const thresholds = getAnomalyThresholds(model)
  const [tempThresholds, setTempThresholds] = React.useState(thresholds)

  const thresholdMutation = useUpdateModelAnomalyConditions({ id: model.id })

  const runAnomalyDetectionMutation = useRunAnomalyDetectionMutation()

  const [editing, setEditing] = React.useState(false)
  const [filter, setFilter] = React.useState(
    condition ? stringify(condition) : null,
  )
  const isThresholdModified = !isEqual(thresholds, tempThresholds)

  const showFilters = instantUpdate || model.state === NOT_RUNNING
  const isConditionModified =
    (model.anomalyGenerationFilter && !condition) ||
    (condition && model.anomalyGenerationFilter !== stringify(condition))
  const showInfo =
    instantUpdate ||
    (model.state === NOT_RUNNING &&
      !editing &&
      (isConditionModified || isThresholdModified))

  React.useEffect(() => {
    if (isThresholdModified && instantUpdate) {
      setTempThresholds(thresholds)
    }
  }, [instantUpdate, isThresholdModified, thresholds])

  React.useEffect(() => {
    setFilter(condition ? stringify(condition) : null)
  }, [condition])

  const handleUpdateThreshold = (
    updatedThresholds: GqlAnomalyConditionsFragment[],
  ): void => {
    setEditing(false)
    if (instantUpdate) {
      thresholdMutation.mutate({
        conditions: updatedThresholds.map(t => ({
          errorScoreKind: t.errorScore.kind,
          transform: t.errorScore.transform,
          thresholdKind: t.thresholdKind,
          threshold: t.threshold,
        })),
      })
    } else {
      setTempThresholds(updatedThresholds)
    }
  }

  const handleAnomalyDetection = (): void => {
    if (instantUpdate) {
      runAnomalyDetectionMutation.mutate({
        id: model.id,
        replaceAnomalies: false,
      })
    } else {
      runAnomalyDetectionMutation.mutate({
        id: model.id,
        replaceAnomalies: true,
        conditions: tempThresholds.map(t => ({
          errorScoreKind: t.errorScore.kind,
          transform: t.errorScore.transform,
          thresholdKind: t.thresholdKind,
          threshold: t.threshold,
        })),
        anomalyGenerationFilter: condition ? stringify(condition) : null,
      })
    }
  }

  const handleEditing = (editing: boolean, clear?: boolean): void => {
    setEditing(editing)
    setFilter(condition && !clear ? stringify(condition) : null)
  }

  const showAnomalyScore = (state: string): boolean => {
    // FIXME
    // we want the anomaly score data to still be available in archived mode
    // what we really want to check here is if the model has
    // _ever_ been run.
    return isRunning(state) || state === ARCHIVED
  }

  return (
    <div className="flex flex-col gap-[1em]">
      {model.state === NEW && <NewModelInfo />}
      <div className="flex flex-col gap-[1em] medium:flex-row">
        <ModelOverview
          model={{ ...model, anomalyGenerationFilter: filter }}
          className="flex flex-1 flex-col"
        />
        <ModelActions
          model={model}
          className="medium:min-w-[calc(300px-1em)]"
        />
      </div>
      <ConfigWarning model={model} />
      {model.state === ERROR && model.errorMessage && (
        <Banner className="col-span-2" variant="error">
          {`Error message: ${model.errorMessage}`}
        </Banner>
      )}
      {showInfo && (
        <AnomalyAnalysisInfo
          disabled={tempThresholds.length === 0}
          withConfirmation={model.state === NOT_RUNNING}
          pending={runAnomalyDetectionMutation.isLoading}
          onUpdate={handleAnomalyDetection}
        />
      )}
      {showFilters && (
        <>
          <AnomalyFilter
            tags={[...model.inputTags.map(t => t.tagName), model.tag.tagName]}
            model={model}
            instantUpdate={instantUpdate}
            onEditing={handleEditing}
          />
          <AnomalyThresholds
            model={model}
            thresholds={tempThresholds}
            handleUpdateThresholds={handleUpdateThreshold}
          />
        </>
      )}
      {hasRunAnomalyDetection(model.state) && <Anomalies model={model} />}
      {hasRunInitialInference(model.state) ? (
        <OutputTagAndPrediction model={model} anomaly={undefined} />
      ) : (
        <OutputTag model={model} />
      )}
      {showAnomalyScore(model.state) && <AnomalyScore model={model} />}
      <InputTags model={model} />
    </div>
  )
}

import { ReactNode } from 'react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import * as duration from 'duration-fns'
import { isTimeRange } from 'src/types'
import useTimeRange from 'src/contexts/timeRange'
import { useSite } from 'src/contexts/site'
import { Icon } from 'src/components/ui'
import { LabelValue, TimePickerInput } from './TimePickerInput'

function TimePickerNavigationButton({
  children,
  onClick,
  disabled,
}: {
  children: ReactNode
  onClick?: () => void
  disabled?: boolean
}): JSX.Element {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className="flex w-[30px] cursor-pointer place-content-center rounded-2xs border border-solid border-border bg-background p-xs transition duration-200 hover:enabled:shadow-md disabled:opacity-50"
    >
      {children}
    </button>
  )
}

export function TimePickerNavigation(): JSX.Element {
  const { isWorkshop } = useSite()

  const {
    setTimeRange,
    timeRange: { from, to },
    undo,
    hasHistory,
    live: isLive,
    setLive,
  } = useTimeRange()

  const half = (to - from) / 2

  const options: LabelValue[] = [
    { label: 'Past 30 minutes', value: 'PT30M', summaryLabel: '30m' },
    { label: 'Past hour', value: 'PT1H', summaryLabel: '1h' },
    { label: 'Past day', value: 'P1D', summaryLabel: '1d' },
    { label: 'Past week', value: 'P1W', summaryLabel: '1w' },
    { label: 'Past month', value: 'P1M', summaryLabel: '1mo' },
    { label: 'Past 6 months', value: 'P6M', summaryLabel: '6mo' },
  ]

  if (isWorkshop) {
    options.unshift({
      label: 'Metropolis ',
      value: 'metropolis',
      summaryLabel: 'Metropolis',
    })
  }

  return (
    <div className="flex items-center justify-end gap-2xs">
      <TimePickerNavigationButton
        onClick={() => setTimeRange({ from: from - half, to: to - half })}
      >
        <Icon icon={light('arrow-left')} />
      </TimePickerNavigationButton>
      <TimePickerInput
        value={{ from, to }}
        isLive={isLive}
        options={options}
        onChange={value => {
          if (isTimeRange(value)) {
            setTimeRange(value)
          } else {
            setLive({
              live: true,
              period: duration.toMilliseconds(value.period),
            })
          }
        }}
      />
      <TimePickerNavigationButton
        disabled={isLive}
        onClick={() => setTimeRange({ from: from + half, to: to + half })}
      >
        <Icon icon={light('arrow-right')} />
      </TimePickerNavigationButton>
      <TimePickerNavigationButton
        onClick={() => {
          if (isLive) {
            setLive({
              live: true,
              period: Math.abs(to - from) * 2,
            })
          } else {
            setTimeRange({ from: from - half, to: to + half })
          }
        }}
      >
        <Icon icon={light('magnifying-glass-minus')} />
      </TimePickerNavigationButton>
      <TimePickerNavigationButton
        disabled={!hasHistory}
        onClick={() => hasHistory && undo && undo()}
      >
        <Icon icon={light('arrow-rotate-left')} />
      </TimePickerNavigationButton>
      <TimePickerNavigationButton onClick={() => setLive({ live: !isLive })}>
        <Icon icon={isLive ? light('pause') : light('play')} />
      </TimePickerNavigationButton>
    </div>
  )
}

import { useState } from 'react'
import classNames from 'classnames'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Button } from 'src/components/ui'
import { useTags, useTag, useTagData } from 'tags/api'
import { mapModelToModelRecord, NEW } from 'models/model/model.utils'
import { SiteRole, isDefined } from 'src/types'
import { useAddInputTagMutation, useRemoveInputTagMutation } from 'models/api'
import { Icon, Text, Tooltip, Card, TimeSeriesChart } from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import { TagDto, Anomaly, FetchModel, ModelTagBase } from 'src/services'
import { AddInputTagsModal, getInputTagWarnings } from 'pages/site/models'
import { TagLabel } from '../TagLabel'

interface TrendChartProps {
  id: string
  anomaly?: Anomaly
}

const TrendChart = (props: TrendChartProps): JSX.Element => {
  const { data: tag, isLoading: isTagPending } = useTag(props.id)
  const tagData = useTagData(props)

  return (
    <TimeSeriesChart
      {...props}
      data={[{ ...tag, data: tagData.data }]}
      isPending={isTagPending || tagData.isLoading}
      tooltip
    />
  )
}

interface InputTagProps {
  tag: TagDto
  model?: FetchModel
  anomaly?: Anomaly
}

function InputTag({ tag, model, anomaly }: InputTagProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const messages = getInputTagWarnings(
    tag,
    model?.__typename,
    model?.tag.tagName,
  )
  const isEditable = model?.state === NEW

  const { viewerRole: role } = useSite()

  const removeInputTag = useRemoveInputTagMutation()

  return (
    <>
      {isEditable ? (
        <div className="flex items-center gap-[0.5em]">
          {messages.length > 0 ? (
            <Tooltip
              render={() =>
                messages.map((message, i) => <div key={i}>{message}</div>)
              }
              direction="right"
            >
              <Icon
                icon={light('triangle-exclamation')}
                className="!text-[2em] text-icon-warning"
              />
            </Tooltip>
          ) : (
            <div className="w-l" />
          )}
          <div className="flex-1">
            <TagLabel tagName={tag.tagName} />
          </div>
          <Button
            title={`${isOpen ? 'Hide' : 'Show'} trend`}
            variant="secondary"
            onClick={() => setIsOpen(isOpen => !isOpen)}
          />
          {role !== SiteRole.READER && (
            <Button
              title="Remove"
              variant="secondary"
              buttonColor="danger"
              isPending={removeInputTag.isLoading}
              onClick={() => {
                removeInputTag.mutateAsync({
                  modelId: model.id,
                  tagId: tag.tagNodeId,
                })
              }}
            />
          )}
        </div>
      ) : (
        <div className="flex justify-between">
          <TagLabel tagName={tag.tagName} />
          <Button
            variant="secondary"
            title={`${isOpen ? 'Hide' : 'Show'} trend`}
            onClick={() => setIsOpen(isOpen => !isOpen)}
          />
        </div>
      )}
      {isOpen && <TrendChart id={tag.tagName} anomaly={anomaly} />}
    </>
  )
}

interface InputTagsProps {
  model: FetchModel
  anomaly?: Anomaly
}

export function InputTags({ model, anomaly }: InputTagsProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const { data: tags = [] } = useTags()
  const addInputTag = useAddInputTagMutation()
  const isEditable = model.state === NEW
  const { viewerRole: role } = useSite()

  const modelTags: TagDto[] = model.inputTags
    .map(({ tagName }) => tags.find(tag => tag.tagName === tagName))
    .filter(isDefined)
    .sort((a, b) => {
      const aName = a.displayName || a.tagName
      const bName = b.displayName || b.tagName
      return aName.localeCompare(bName)
    })

  return (
    <Card className="col-span-2">
      <div className="mb-s flex justify-between">
        <Text variant="title" className="mb-xs" bold>
          Input tags
        </Text>
        {isEditable && role !== SiteRole.READER && (
          <>
            <Button
              variant="secondary"
              title="Add input tag"
              onClick={() => setIsOpen(true)}
            />
            <AddInputTagsModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              model={mapModelToModelRecord(model)}
              inputTags={model.inputTags.map(
                ({ tagName }: { tagName: string }) => tagName,
              )}
              addInputTag={tagName => {
                const tag = tags.find(tag => tag.tagName === tagName)
                if (!tag) return
                addInputTag.mutateAsync({
                  modelId: model.id,
                  tagId: tag.tagNodeId,
                })
              }}
            />
          </>
        )}
      </div>
      <ul className="m-0 list-none p-0">
        {modelTags?.map((tag: TagDto, index: number) => (
          <li
            className={classNames(
              'p-[0.5em] hover:bg-background-hover',
              index !== modelTags.length - 1 &&
                'border-0 border-b border-solid border-border',
            )}
            key={tag.tagName}
          >
            <InputTag tag={tag} model={model} anomaly={anomaly} />
          </li>
        ))}
      </ul>
    </Card>
  )
}

import { Button, SelectInput, Text } from 'src/components/ui'
import { OpcUaConnectionConfiguration } from '../../opc-ua-connection.types'
import { useEffect, useRef } from 'react'
import classNames from 'classnames'
import { GqlOpcSecurityMode, GqlOpcSecurityPolicy } from 'src/services'

function findOptionByValue<T extends { value: string }>(
  options: T[] | undefined,
  value: string | undefined,
): T | undefined {
  return options?.find(option => option.value === value)
}

type Props = {
  disabled?: boolean
  config: OpcUaConnectionConfiguration
  setConfig: (config: OpcUaConnectionConfiguration) => void
}

export function SecurityDetails({
  disabled,
  config,
  setConfig,
}: Props): JSX.Element {
  const { securityType, securityOptions } = config
  const { securityTypes, certificates } = securityOptions || {}

  const securityTypeRef = useRef(config.securityType)

  const selectedType = findOptionByValue(securityTypes, securityType)
  const selectedPolicy = findOptionByValue(
    selectedType?.policies,
    config.securityPolicy,
  )
  const selectedCertificate = certificates?.find(
    certificate => certificate.thumbprint === config.securityCertificate,
  )

  const selectCertificate = selectedPolicy && selectedPolicy?.value !== 'NONE'

  useEffect(() => {
    if (securityTypeRef.current !== securityType) {
      securityTypeRef.current = securityType
      setConfig({
        ...config,
        securityPolicy: undefined,
        securityCertificate: undefined,
      })
    }
  }, [config, securityType, setConfig])

  return (
    <div className="flex flex-1 flex-col gap-s rounded-2xs border border-solid border-border p-s">
      <div>
        <Text variant="description">OPC-UA Security</Text>
        <Text className="inline" bold>
          Configure connection security options, based on your server options
        </Text>
      </div>
      <div
        className={classNames(
          'flex gap-4 pb-[24px]',
          selectCertificate && 'border-0 border-b border-solid border-border',
        )}
      >
        <div className="w-1/2 max-w-[280px]">
          <Text bold variant="description" className="mb-1">
            Security Type
          </Text>
          <SelectInput
            disabled={disabled}
            value={selectedType}
            options={config.securityOptions?.securityTypes ?? []}
            onChange={value =>
              setConfig({
                ...config,
                securityType: value as GqlOpcSecurityMode,
              })
            }
            textVariant="description"
            bold
          />
        </div>
        <div className="w-1/2 max-w-[280px]">
          <Text bold variant="description" className="mb-1">
            Specify Security Policy
          </Text>
          <SelectInput
            disabled={disabled || !config.securityType}
            value={selectedPolicy}
            options={selectedType?.policies ?? []}
            onChange={value =>
              setConfig({
                ...config,
                securityPolicy: value as GqlOpcSecurityPolicy,
              })
            }
            textVariant="description"
            bold
          />
        </div>
      </div>
      {selectCertificate && (
        <div className="mt-s flex max-w-full flex-wrap items-end gap-4">
          <div>
            <Text bold variant="description" className="mb-1">
              Select X509 Certificate
            </Text>
            <SelectInput
              className="min-h-[30px] min-w-[280px]"
              disabled={disabled}
              value={selectedCertificate}
              options={config.securityOptions?.certificates ?? []}
              onChange={value =>
                setConfig({ ...config, securityCertificate: value })
              }
              textVariant="description"
              bold
            />
          </div>
          <div>
            <Button
              className="translate-y-3xs"
              variant="primary"
              disabled={false}
              onClick={() => {}}
              title="View Certificate"
            />
          </div>
        </div>
      )}
    </div>
  )
}

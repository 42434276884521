import { Formik, Form, FormikErrors } from 'formik'
import { Asset } from 'src/types'
import { Button, Text, TextInput } from 'src/components/ui'
import { Modal } from 'src/components/ui'

interface CreateAssetModalProps {
  onClickCross: () => void
  onClickConfirm: (name: string) => void
  onClickCancel: () => void
  parentAsset: Asset
  assets: Asset[]
}

interface FormValues {
  assetName: string
}

export function CreateAssetModal({
  onClickCross,
  onClickConfirm,
  onClickCancel,
  parentAsset,
  assets,
}: CreateAssetModalProps): JSX.Element {
  return (
    <Modal title="Create new asset" isOpen close={onClickCross}>
      <div className="mt-3 flex flex-col justify-center gap-4 px-4">
        <div className="flex">
          <Text className="text-lg">Create a new asset under parent: </Text>
          <Text className="text-lg" bold>
            {parentAsset.alias || parentAsset.name}
          </Text>
        </div>
        <Formik
          initialValues={{
            assetName: '',
          }}
          validateOnMount={true}
          validate={values => {
            const errors: FormikErrors<FormValues> = {}

            const lc = values.assetName.toLowerCase()
            const nameExists = assets.some(
              a =>
                a.name.toLowerCase() === lc ||
                (a.alias && a.alias.toLowerCase() === lc),
            )

            if (!values.assetName) {
              errors.assetName = 'Required Field'
            } else if (nameExists) {
              errors.assetName = 'Name is already in use by another asset'
            }
            return errors
          }}
          onSubmit={values => onClickConfirm(values.assetName)}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            errors,
            isValid,
            submitForm,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="flex flex-col gap-2xs">
                <TextInput
                  label="Name:"
                  name="assetName"
                  value={values.assetName}
                  onChange={handleChange}
                  isError={!!errors.assetName}
                />
                {errors.assetName && (
                  <Text variant="small" bold className="text-text-danger">
                    {errors.assetName}
                  </Text>
                )}
              </div>

              <div className="mt-4 flex flex-row justify-center gap-2">
                <Button
                  variant="primary"
                  title="Confirm"
                  disabled={!isValid}
                  onClick={submitForm}
                />
                <Button
                  variant="secondary"
                  title="Cancel"
                  onClick={onClickCancel}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

import { Text } from 'src/components/ui'
import { ModelTypes } from 'src/types'
import classNames from 'classnames'

interface ModelTypeStepProps {
  modelType?: ModelTypes | null
  setModelType: (type: ModelTypes) => void
}

export function ModelTypeStep({
  modelType,
  setModelType,
}: ModelTypeStepProps): JSX.Element {
  return (
    <>
      {/** TODO: handle font sizes better */}
      <Text variant="title" bold className="my-m text-center !text-3xl">
        What is the goal of this model?
      </Text>
      <div className="grid grid-flow-col gap-12">
        <button
          className={classNames(
            'text-left bg-background border-[2px] border-solid rounded-[8px] p-[1em] cursor-pointer',
            modelType === 'ForecastModel'
              ? 'border-border-success-secondary'
              : 'border-border',
          )}
          onClick={() => setModelType('ForecastModel')}
        >
          <Text variant="title" className="!text-2xl">
            To forecast a value
          </Text>
          <Text className="my-s">
            A <span className="font-500">forecast model</span> lets you look
            into the future by predicting the value of a tag.
          </Text>
        </button>
        <button
          className={classNames(
            'text-left bg-background border-[2px] border-solid rounded-[8px] p-[1em] cursor-pointer',
            modelType === 'AnomalyModel'
              ? 'border-border-success-secondary'
              : 'border-border',
          )}
          onClick={() => setModelType('AnomalyModel')}
        >
          <Text variant="title" className="!text-2xl">
            To identify anomalies
          </Text>
          <Text className="my-s">
            An <span className="font-500">anomaly model</span> identifies when
            the process deviates from expected behavior.
          </Text>
        </button>
      </div>
    </>
  )
}

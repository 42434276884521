import Highlighter from 'react-highlight-words'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { humanDateTime, timeAgo } from 'src/utility/time'
import { ModelState } from 'src/pages/site/models'
import { Icon, Text, TableConfig, getBaseFilterParams } from 'src/components/ui'
import { Link } from 'react-router-dom'
import { TableModel } from 'models/model.types'
import { label } from 'models/model/model.utils'

export const getModelTableConfig = (filter = ''): TableConfig<TableModel> => {
  return {
    allowPaging: true,
    pageSize: 10,
    allowSorting: true,
    allowSearch: false,
    columns: [
      {
        field: 'name',
        headerName: 'Name',
        minWidth: 100,
        flex: 400,
        filterParams: getBaseFilterParams(true),
        cellRenderer: ({ data: { name, viewerHasStarred, link } }: any) => {
          return (
            <Link
              to={link}
              className="inline-block size-full text-inherit no-underline"
            >
              {viewerHasStarred && (
                <span style={{ paddingRight: '3px' }}>
                  <Icon className="text-[orange]" icon={solid('star-sharp')} />
                </span>
              )}
              <Text bold className="inline">
                <Highlighter
                  searchWords={filter.split(' ')}
                  textToHighlight={name}
                  autoEscape
                />
              </Text>
            </Link>
          )
        },
      },
      {
        field: 'type.name',
        headerName: 'Type',
        flex: 200,
        minWidth: 100,
        cellRenderer: ({
          data: {
            type: { name },
            method,
          },
        }: any) => {
          const m =
            name === 'Forecast'
              ? method.name.replace(/Forecast (\d)H/g, (_: any, n: any) =>
                  n === '1' ? '1 hour' : `${n} hours`,
                )
              : method.name
          return (
            <>
              <Text>
                <Highlighter
                  searchWords={filter.split(' ')}
                  textToHighlight={name}
                  autoEscape
                />
              </Text>
              <Text className="text-text-tertiary">
                <Highlighter
                  searchWords={filter.split(' ')}
                  textToHighlight={m}
                  autoEscape
                />
              </Text>
            </>
          )
        },
      },
      {
        field: 'tagDisplayName',
        headerName: 'Output Tag',
        flex: 200,
        minWidth: 130,
        filterParams: getBaseFilterParams(true),
        cellRenderer: ({
          data: {
            tagDisplayName,
            tag: { description, engUnit },
          },
        }: any) => {
          return (
            <>
              <Text>
                <Highlighter
                  searchWords={filter.split(' ')}
                  textToHighlight={tagDisplayName}
                  autoEscape
                />
              </Text>
              <Text className="text-text-tertiary">
                <Highlighter
                  searchWords={filter.split(' ')}
                  textToHighlight={
                    engUnit && engUnit.toLowerCase() !== 'none'
                      ? `${description} (${engUnit})`
                      : description
                  }
                  autoEscape
                />
              </Text>
            </>
          )
        },
      },
      {
        field: 'created',
        headerName: 'Created',
        flex: 200,
        minWidth: 120,
        filter: '',
        cellRenderer: ({ value }: any) => (
          <>
            <div>
              <Text>{humanDateTime(value)}</Text>
            </div>
            <Text className="text-text-tertiary">{timeAgo(value)}</Text>
          </>
        ),
      },
      {
        field: 'state',
        headerName: 'State',
        minWidth: 150,
        flex: 200,
        // Format filter options to human readable labels
        filterValueGetter: ({ getValue }) => {
          return label(getValue('state'))
        },
        cellRenderer: ({ data: { state } }: any) => {
          return (
            <ModelState state={state} className="w-full">
              <Text>
                <Highlighter
                  searchWords={filter.split(' ')}
                  textToHighlight={label(state) || ''}
                  autoEscape
                />
              </Text>
            </ModelState>
          )
        },
      },
    ],
  }
}

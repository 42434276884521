import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { useSite } from 'src/contexts/site'
import * as api from 'src/services'
import { MODELS_QUERY_KEY } from 'models/api'
import { datadogRum } from '@datadog/browser-rum'

type Variables = Omit<api.CreateModel, 'factory'>

export function useCreateModelMutation(): UseMutationResult<
  api.GqlModelBaseFragment,
  unknown,
  Variables
> {
  const queryClient = useQueryClient()
  const { id: factory } = useSite()

  return useMutation({
    mutationFn: async (input: Variables) => {
      const model = await api.createModel({ ...input, factory })
      if (!model) {
        throw new Error('Model creation failed')
      }
      return model
    },
    onSuccess: data => {
      datadogRum.addAction('Model created', {
        id: data.id,
        type: data.type.name,
        method: data.method.name,
        inputTags: data.inputTags.map(t => t.tagName),
        outputTag: data.tag.tagName,
      })
      queryClient.invalidateQueries([MODELS_QUERY_KEY, factory])
    },
  })
}

type CopyMutationInput = {
  model: api.FetchModel
  factory: string
}

export function useCopyModelMutation(): UseMutationResult<
  api.GqlModelBaseFragment | null | undefined,
  unknown,
  CopyMutationInput
> {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ model, factory }: CopyMutationInput) => {
      return api.createModel({
        name: `Copy of ${model.name}`,
        typeId: model.type.id,
        methodId: model.method.id,
        tagId: model.tag.id,
        inputTagIds: model.inputTags.map(t => t.id),
        trainingStart: model.trainingStart,
        trainingEnd: model.trainingEnd,
        trainingPeriod: model.trainingPeriod ?? undefined,
        factory,
      })
    },
    onSuccess: (_, input) => {
      queryClient.invalidateQueries([MODELS_QUERY_KEY, input.factory])
    },
  })
}

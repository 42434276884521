import { Link } from 'react-router-dom'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Icon, Text, Spinner, Card } from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import { LatestComment } from 'src/services'
import { humanDateTime, timeAgo } from 'src/utility/time'
import { CardHeader } from '../CardHeader'
import { useLatestAnomalyComments } from './anomalyComments.api'

export function AnomalyCommentsCard(): JSX.Element {
  const query = useLatestAnomalyComments()

  const renderComments = (comments: LatestComment[]): JSX.Element => {
    return comments.length > 0 ? (
      <ul className="m-0 grid list-none gap-2 overflow-y-auto break-words p-0">
        {comments
          .sort((a, b) => b.at.valueOf() - a.at.valueOf())
          .map(comment => (
            <li key={comment.id}>
              <AnomalyComment {...comment} />
            </li>
          ))}
      </ul>
    ) : (
      <div>
        <Text variant="content">No comments</Text>
      </div>
    )
  }

  return (
    <Card>
      <div className="grid grid-rows-[auto_1fr] p-s">
        <CardHeader>
          <Icon icon={light('comment')} />
          <Text variant="title">Latest comments</Text>
        </CardHeader>
        {query.isLoading ? <Spinner /> : renderComments(query.data ?? [])}
      </div>
    </Card>
  )
}

function AnomalyComment({
  by,
  at,
  comment,
  anomaly,
}: LatestComment): JSX.Element {
  const { rootLink } = useSite()

  return (
    <Link
      className="block text-current no-underline hover:bg-background-hover"
      to={`${rootLink}/anomalies/${anomaly.id}`}
    >
      <div>
        <p className="my-0 text-medium leading-1.5">
          <span className="font-500">{by}</span> commented on anomaly{' '}
          {anomaly.id} (model name: {anomaly.model.name}, start time:{' '}
          {humanDateTime(anomaly.start.valueOf())}):
        </p>
      </div>
      <p className="my-0 whitespace-pre-wrap text-medium leading-1.5">
        "{comment}"
      </p>
      <Text variant="content" className="text-text-tertiary">
        {timeAgo(at.valueOf())}
      </Text>
    </Link>
  )
}

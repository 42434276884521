import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useState } from 'react'
import { Button, Text, Card, TimePickerNavigation } from 'src/components/ui'
import { FetchAnomalyModel, GqlAnomalyConditionsFragment } from 'src/services'
import { AddAnomalyThreshold, AnomalyThreshold } from './components'
import classNames from 'classnames'
import { areSameThresholdTypes } from './util'

type Props = {
  model: FetchAnomalyModel
  thresholds: GqlAnomalyConditionsFragment[]
  handleUpdateThresholds: (thresholds: GqlAnomalyConditionsFragment[]) => void
}

export function AnomalyThresholds({
  model,
  thresholds,
  handleUpdateThresholds,
}: Props): JSX.Element {
  const [thresholdEditOpen, setThresholdEditOpen] = useState<number[]>([])
  const [isAddAnomalyThresholdOpen, setIsAddAnomalyThresholdOpen] =
    useState(false)

  const handleThresholdChange = (
    threshold: GqlAnomalyConditionsFragment,
    initialThreshold: GqlAnomalyConditionsFragment,
  ): void => {
    const thresholdIndex = thresholds.findIndex(t =>
      areSameThresholdTypes(t, initialThreshold),
    )
    handleUpdateThresholds(
      thresholds.map(t => {
        if (areSameThresholdTypes(t, initialThreshold)) {
          return threshold
        } else return t
      }),
    )
    setThresholdEditOpen(thresholdEditOpen.filter(i => i !== thresholdIndex))
  }

  const handleThresholdAdd = (
    threshold: GqlAnomalyConditionsFragment,
  ): void => {
    handleUpdateThresholds([...thresholds, threshold])
    setIsAddAnomalyThresholdOpen(false)
  }

  const handleThresholdDelete = (
    threshold: GqlAnomalyConditionsFragment,
  ): void => {
    const thresholdIndex = thresholds.findIndex(t =>
      areSameThresholdTypes(t, threshold),
    )
    handleUpdateThresholds(
      thresholds.filter(t => !areSameThresholdTypes(t, threshold)),
    )
    setThresholdEditOpen(thresholdEditOpen.filter(i => i !== thresholdIndex))
  }

  return (
    <Card className="col-span-2 flex flex-col items-start gap-s">
      <div className="flex w-full items-center justify-between">
        <Text variant="title" bold>
          Anomaly Thresholds
        </Text>
        <TimePickerNavigation />
      </div>
      <Text>
        Anomaly thresholds define the difference between anomalous and expected
        behavior. Specify the threshold alert if the value goes{' '}
        <span className="font-500"> above and/or below</span> the expected
        value.
      </Text>
      <div className="w-full">
        {thresholds.map((t, index) => {
          return (
            <AnomalyThreshold
              key={index}
              model={model}
              onSave={updatedThreshold =>
                handleThresholdChange(updatedThreshold, t)
              }
              onDelete={() => handleThresholdDelete(t)}
              open={thresholdEditOpen.includes(index)}
              setOpen={open =>
                setThresholdEditOpen(
                  open
                    ? [...thresholdEditOpen, index]
                    : thresholdEditOpen.filter(i => i !== index),
                )
              }
              threshold={t}
              thresholds={thresholds}
              className={classNames(
                'border-solid border-0 border-t border-border',
                index === thresholds.length - 1 && 'border-b',
              )}
            />
          )
        })}
      </div>
      {!isAddAnomalyThresholdOpen ? (
        thresholds.length !==
          model.availableErrorScores?.reduce(
            (acc, curr) => acc + (curr.kind === 'SIGNED' ? 2 : 1),
            0,
          ) && (
          <Button
            variant="icon-secondary"
            title="Add Threshold"
            icon={light('circle-plus')}
            onClick={() => setIsAddAnomalyThresholdOpen(true)}
          />
        )
      ) : (
        <AddAnomalyThreshold
          model={model}
          thresholds={thresholds}
          onCancel={() => setIsAddAnomalyThresholdOpen(false)}
          onSubmit={handleThresholdAdd}
        />
      )}
    </Card>
  )
}

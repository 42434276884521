import { humanDateTime } from 'src/utility/time'
import { Text } from 'src/components/ui'
import { Link } from 'react-router-dom'
import { TableConfig } from '../table.types'

export const gatewaysTableConfig = (
  orgId: string,
  siteId: string,
  gatewayDetails = false,
): TableConfig => {
  return {
    pageSize: 10,
    allowPaging: true,
    pageSizes: [10, 50, 100],
    allowSorting: true,
    columns: [
      {
        field: 'id',
        headerName: 'Gateway ID',
        flex: 200,
        cellRenderer: ({ value }: any) =>
          gatewayDetails ? (
            <Link
              to={`/settings/orgs/${orgId}/sites/${siteId}/gateways/${value}`}
              className="text-text-brand no-underline"
            >
              <Text bold variant="code">
                {value}
              </Text>
            </Link>
          ) : (
            <Text variant="code" className="text-text-tertiary">
              {value}
            </Text>
          ),
      },
      {
        field: 'hostname',
        headerName: 'Location',
        flex: 200,
        cellRenderer: ({ value }: any) => (
          <Text variant="content">{value}</Text>
        ),
      },
      {
        field: 'version',
        headerName: 'Version',
        flex: 200,
        cellRenderer: ({ value }: any) => (
          <Text variant="content">{value}</Text>
        ),
      },
      {
        field: 'createdOn',
        headerName: 'Created On',
        flex: 200,
        cellRenderer: ({ value }: any) => (
          <Text variant="content">{humanDateTime(value)}</Text>
        ),
      },
      {
        field: 'startTime',
        headerName: 'Start Time',
        flex: 200,
        cellRenderer: ({ value }: any) => (
          <Text variant="content">{humanDateTime(value)}</Text>
        ),
      },
      {
        field: 'lastPing',
        headerName: 'Last Heartbeat',
        flex: 200,
        cellRenderer: ({ value }: any) => (
          <Text variant="content">{humanDateTime(value)}</Text>
        ),
      },
    ],
  }
}

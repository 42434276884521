import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Icon, Text, Tooltip, TableConfig } from 'src/components/ui'

interface Actions {
  toggleRow: any
}

export const getForecastModelsConfig = ({
  toggleRow,
}: Actions): TableConfig => {
  return {
    pageSize: 50,
    pageSizes: [10, 50, 100, 200],
    allowSorting: true,
    allowPaging: true,
    disallowSelection: true,
    columns: [
      {
        field: 'isSelected',
        headerName: '',
        flex: 60,
        minWidth: 60,
        menuTabs: [],
        cellRenderer: ({ data: { id, isSelected } }: any) => (
          <Tooltip
            render={() =>
              isSelected ? 'Remove from forecast view' : 'Add to forecast view'
            }
          >
            <span
              className="fa-layers fa-fw"
              style={{ cursor: 'pointer' }}
              onClick={() => toggleRow({ id })}
            >
              <Icon icon={light('chart-line')} />
              {isSelected ? (
                <Icon
                  icon={regular('minus')}
                  className="translate-x-[11px] translate-y-[-6px] !text-[7px] text-text-danger"
                />
              ) : (
                <Icon
                  icon={regular('plus')}
                  className="translate-x-[11px] translate-y-[-6px] !text-[7px] text-icon-brand"
                />
              )}
            </span>
          </Tooltip>
        ),
        // colAllowFiltering: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 200,
        cellRenderer: ({ value }: any) => (
          <Text className="pl-[5px]">{value}</Text>
        ),
      },
      {
        headerName: 'Tag Name',
        flex: 200,
        valueGetter: ({
          data: {
            tag: { displayName, tagName },
          },
        }: any) => displayName ?? tagName,
        cellRenderer: ({ value }: any) => {
          return <Text>{value}</Text>
        },
      },
      {
        field: 'method.name',
        headerName: 'Method',
        flex: 200,
        cellRenderer: ({ value }: any) => <Text>{value}</Text>,
      },
    ],
  }
}

import { useMemo } from 'react'
import { sub } from 'date-fns'
import {
  useQuery,
  useQueries,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import * as api from 'src/services'
import { TimeSeriesData } from 'src/types'
import useTimeRange from 'src/contexts/timeRange'
import { processTimeSeries } from 'src/utility/timeSeries'
import { timeSeriesRefetch } from 'trend/api'
import { useSite } from 'src/contexts/site'
import { useTagData } from 'tags/api'

export function historicalForecastQuery(
  req: api.FetchHistoricalForecast,
): UseQueryOptions<TimeSeriesData[]> {
  return {
    queryKey: ['historicalForecast', req],
    queryFn: async () => {
      const data = await api.fetchHistoricalForecast(req)
      return processTimeSeries(data)
    },
    refetchInterval: () => timeSeriesRefetch(req.timeRange),
  }
}

interface UseHistoricalForecast {
  id: string
  offset: number
}

export function useHistoricalForecast({
  id,
  offset,
}: UseHistoricalForecast): UseQueryResult<TimeSeriesData[]> {
  const { id: factory } = useSite()
  const { timeRange } = useTimeRange()
  const query = historicalForecastQuery({ id, offset, factory, timeRange })
  return useQuery(query)
}

export type Forecast = {
  tagName: string
  madeAt: number
  prediction: TimeSeriesData[]
}

function modelForecastQuery(req: api.FetchForecast): UseQueryOptions<Forecast> {
  return {
    queryKey: ['modelForecast', req],
    queryFn: async () => {
      const { tagName, madeAt, prediction } = await api.fetchForecast(req)
      return {
        tagName,
        madeAt: madeAt.valueOf(),
        prediction: processTimeSeries(prediction),
      }
    },
  }
}

type ForecastData = {
  prediction?: TimeSeriesData[] | undefined
  tagData?: TimeSeriesData[] | undefined
}

export function useModelForecast(modelId: string): [ForecastData, boolean] {
  const { id: factory } = useSite()
  const query = modelForecastQuery({ modelId, factory })
  const modelForecast = useQuery({
    ...query,
    // Refetch the forecast every minute
    refetchInterval: 1000 * 60,
  })

  const forecast = modelForecast.data

  const timeRange = useMemo(() => {
    const madeAt = forecast?.madeAt ?? Date.now()
    return {
      from: sub(madeAt, { hours: 3 }).valueOf(),
      to: madeAt,
    }
  }, [forecast?.madeAt])

  const tagDataProps = forecast
    ? { id: forecast.tagName, timeRange }
    : undefined

  // this will refetch when the props change.
  const tagData = useTagData(tagDataProps)

  return [
    {
      prediction: forecast?.prediction,
      tagData: tagData.data,
    },
    modelForecast.isLoading || tagData.isLoading,
  ]
}

export function useForecasts(ids: string[]): UseQueryResult<Forecast>[] {
  const { id: factory } = useSite()
  const queries = ids.map(modelId => modelForecastQuery({ modelId, factory }))
  return useQueries({ queries })
}

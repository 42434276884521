import { Button, DynamicTable, Spinner, Text } from 'src/components/ui'
import { useWorkspace } from '../api'
import { useParams } from 'react-router-dom'
import { getWorkspaceAnalysesConfig } from './workspace-analyses'
import { ErrorDisplay } from 'pages/app'
import { AnalysisCard } from './workspace-analyses/components'

export function WorkspaceAnalyses(): JSX.Element {
  const { workspaceId } = useParams()
  if (!workspaceId) {
    throw new Error('Workspace id is required')
  }
  const workspaceQuery = useWorkspace(workspaceId)

  if (workspaceQuery.isLoading) {
    return <Spinner />
  }

  if (workspaceQuery.isError) {
    return (
      <ErrorDisplay
        error={workspaceQuery.error}
        message="Failed to fetch the Workspace"
        action={workspaceQuery.refetch}
      />
    )
  }
  const cardDisplay = true
  return (
    <div className="flex flex-1 flex-col gap-s py-s">
      <div className="flex items-center justify-between">
        <Text variant="description">
          Lists all of the analyses assigned to this workspace.
        </Text>
        <Button variant="primary" title="Add New Analysis" />
      </div>
      {cardDisplay ? (
        Object.values(workspaceQuery.data.analyses).map(a => (
          <AnalysisCard key={a.id} analysis={a} />
        ))
      ) : (
        <DynamicTable
          data={Object.values(workspaceQuery.data.analyses)}
          config={getWorkspaceAnalysesConfig()}
          id={`workspace-analyses-${workspaceId}`}
        />
      )}
    </div>
  )
}

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from 'react-router-dom'
import { useMySites } from 'orgs-sites/site/api'
import { Icon, Text } from 'src/components/ui'
import { useGateways } from 'gateway/api'

type Props = {
  orgId: string
  siteId: string
  gatewayId: string
}

export function GatewayDetailsNavigation({
  orgId,
  siteId,
  gatewayId,
}: Props): JSX.Element {
  const navigate = useNavigate()
  const sitesQuery = useMySites()

  const { data: gateways } = useGateways(siteId)

  const backUrl = `/settings/orgs/${orgId}/sites/${siteId}`

  const selectedGateway = gateways?.find(gateway => gateway.id === gatewayId)
  const site = sitesQuery.data?.find(site => site.id === siteId)

  return (
    <div className="flex items-center gap-xs">
      <Icon
        size="big"
        className="cursor-pointer"
        icon={regular('circle-chevron-left')}
        onClick={() => navigate(backUrl)}
      />
      <div className="flex items-center">
        <Text variant="content-rubik" bold className="!mt-3xs inline">
          {`${site?.name ?? 'Site'} | Gateways |`}
        </Text>{' '}
        <Text variant="title" bold className="inline">
          &nbsp;
          {selectedGateway?.hostname ?? 'Gateway'}
        </Text>
      </div>
    </div>
  )
}

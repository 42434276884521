import { Link } from 'react-router-dom'
import queryString from 'query-string'
import { ReactNode } from 'react'
import { ModelType, TimeRange } from 'src/types'
import { ChartOptions, ChartType } from 'src/types/chartTypes'
import { timeRangeUrlProps } from 'src/utility/time'
import { hasRunInitialInference } from 'models/model/model.utils'
import { useSite } from 'src/contexts/site'
import { GqlTag } from 'src/services'

const offset = (method: string): number => {
  switch (method) {
    case 'Forecast 1H':
      return 60
    case 'Forecast 2H':
      return 120
    case 'Forecast 4H':
      return 240
    default:
      return 0
  }
}

interface TrendLinkProps {
  timeRange?: TimeRange
  model?: ModelType
  tags?: GqlTag[]
  children: ReactNode
}

export function TrendLink({
  timeRange,
  model,
  tags = [],
  children,
  ...rest
}: TrendLinkProps): JSX.Element {
  let nextId = 0

  function getNextId(): number {
    const id = nextId
    nextId++
    return id
  }

  let charts = tags
    .filter((value, index, self) => self.indexOf(value) === index)
    .map<ChartOptions>(({ tagName }) => ({
      type: ChartType.TimeSeries,
      id: getNextId(),
      data: [{ type: 'tag', id: tagName }],
    }))
  const { rootLink } = useSite()

  if (model && hasRunInitialInference(model.state)) {
    const { id } = model
    if (model.type.name === 'Forecast') {
      charts.push({
        id: getNextId(),
        type: ChartType.TimeSeries,
        data: [
          {
            type: 'forecast',
            id,
            offset: offset(model.method.name),
          },
        ],
      })
    } else {
      charts = charts.concat([
        {
          data: [{ type: 'prediction', id }],
          id: getNextId(),
          type: ChartType.TimeSeries,
        },
        {
          data: [{ type: 'spikeScore', id }],
          id: getNextId(),
          type: ChartType.TimeSeries,
        },
        {
          data: [{ type: 'shortScore', id }],
          id: getNextId(),
          type: ChartType.TimeSeries,
        },
      ])
    }
  }

  return (
    <Link
      {...rest}
      to={`${rootLink}/trend?${queryString.stringify({
        ...(timeRange ? timeRangeUrlProps(timeRange) : {}),
        charts: JSON.stringify(charts),
      })}`}
    >
      {children}
    </Link>
  )
}

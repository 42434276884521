import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { nanoid } from 'nanoid'
import { useRef, useState } from 'react'
import { useClickOutside } from 'src/utility'
import { ChartMarkerType } from 'src/types/chartTypes'
import {
  Icon,
  TextInput,
  Text,
  SelectInput,
  NumberInput,
} from 'src/components/ui'
import { MarkerColorSelector } from './MarkerColorSelector'
import { MarkersProps } from './Markers'
import { addMarker, removeMarker, updateMarker } from 'src/contexts/charts'

const markerOptions = [
  { label: 'Line', value: ChartMarkerType.LINE },
  { label: 'Range', value: ChartMarkerType.RANGE },
]

export type MarkerProps = MarkersProps & {
  id: string
}

export function Marker({ id, chart, setChart }: MarkerProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)
  const marker = chart.markers?.find(m => m.id === id)

  useClickOutside(dropdownRef, () => setIsOpen(false))

  if (!marker) return <></>

  function changeMarkerLabel(label: string): void {
    setChart(updateMarker(chart, id, m => ({ ...m, label })))
  }

  function handleMarkerTypeChange(type: ChartMarkerType): void {
    if (!marker || marker.type === type) return
    if (
      marker.type === ChartMarkerType.LINE &&
      type === ChartMarkerType.RANGE
    ) {
      setChart(updateMarker(chart, id, m => ({ ...m, type, from: 0, to: 1 })))
    }
    if (
      marker.type === ChartMarkerType.RANGE &&
      type === ChartMarkerType.LINE
    ) {
      setChart(updateMarker(chart, id, m => ({ ...m, type, value: 0 })))
    }
  }

  function handleMarkerValueChange(value: number): void {
    setChart(updateMarker(chart, id, m => ({ ...m, value })))
  }

  function handleMarkerRangeChange(from: number, to: number): void {
    setChart(updateMarker(chart, id, m => ({ ...m, from, to })))
  }

  function handleDeleteMarker(): void {
    if (!marker) return
    setChart(removeMarker(chart, marker.id))
  }

  function handleDuplicateMarker(): void {
    if (!marker) return
    setChart(addMarker(chart, { ...marker, id: nanoid() }))
  }

  return (
    <div className="flex flex-col gap-xs rounded-2xs bg-background-disabled-light p-xs">
      <div className="relative flex items-center justify-between gap-xs">
        <TextInput
          className="w-full"
          containerStyles="!p-2xs"
          placeholder="Add label"
          value={marker.label || ''}
          onChange={e => changeMarkerLabel(e.target.value)}
        />
        <Icon
          icon={regular('ellipsis')}
          className="cursor-pointer p-2xs text-icon"
          size="regular"
          onClick={() => setIsOpen(!isOpen)}
        />
        {isOpen && (
          <div
            ref={dropdownRef}
            className="absolute bottom-0 right-0 rounded-2xs border border-solid border-border bg-background shadow-dropdown"
          >
            <div
              onClick={handleDeleteMarker}
              className="flex cursor-pointer items-center gap-xs border-0 border-b border-solid border-border p-xs transition hover:bg-background-hover"
            >
              <Icon size="xsmall" icon={light('trash-alt')} />
              <Text variant="description" bold className="pt-3xs">
                Delete marker
              </Text>
            </div>
            <div
              onClick={handleDuplicateMarker}
              className="flex cursor-pointer items-center gap-xs p-xs transition hover:bg-background-hover"
            >
              <Icon size="xsmall" icon={light('copy')} />
              <Text variant="description" bold className="pt-3xs">
                Duplicate marker
              </Text>
            </div>
          </div>
        )}
      </div>
      <div className="flex items-center gap-2xs">
        <SelectInput
          className="min-w-[70px] !p-2xs"
          textVariant="description"
          bold
          value={markerOptions.find(o => o.value === marker.type)}
          options={markerOptions}
          onChange={v => handleMarkerTypeChange(v as ChartMarkerType)}
        />
        {marker.type === ChartMarkerType.LINE ? (
          <NumberInput
            containerStyles="!p-2xs"
            className="max-w-[80px]"
            value={marker.value}
            onChange={val => handleMarkerValueChange(val || 0)}
          />
        ) : (
          <>
            <NumberInput
              containerStyles="!p-2xs"
              className="max-w-[60px]"
              value={marker.from}
              max={marker.to}
              onChange={val => handleMarkerRangeChange(val || 0, marker.to)}
            />
            <NumberInput
              containerStyles="!p-2xs"
              className="max-w-[60px]"
              min={marker.from}
              value={marker.to}
              onChange={val => handleMarkerRangeChange(marker.from, val || 1)}
            />
          </>
        )}
      </div>
      <div className="flex items-center gap-2xs">
        <MarkerColorSelector chart={chart} setChart={setChart} id={id} />
      </div>
    </div>
  )
}

import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { nanoid } from 'nanoid'
import { Button } from 'src/components/ui'
import {
  ChartMarkerType,
  ChartOptions,
  LineChartMarker,
  markerColorOptions,
} from 'src/types/chartTypes'
import { Marker } from './Marker'
import { addMarker } from 'src/contexts/charts'

export type MarkersProps = {
  chart: ChartOptions
  setChart: (chart: ChartOptions) => void
}

export function Markers({ chart, setChart }: MarkersProps): JSX.Element {
  const defaultMarker: LineChartMarker = {
    id: nanoid(),
    type: ChartMarkerType.LINE,
    value: 0,
    color: markerColorOptions[0].value,
    label: '',
  }

  return (
    <div className="flex flex-col gap-xs">
      {chart.markers?.map(marker => (
        <Marker
          key={marker.id}
          id={marker.id}
          chart={chart}
          setChart={setChart}
        />
      ))}
      <div>
        <Button
          variant="icon-secondary"
          icon={light('plus')}
          title="Add marker"
          onClick={() => setChart(addMarker(chart, defaultMarker))}
        />
      </div>
    </div>
  )
}

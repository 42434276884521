import { z } from 'zod'

export const ForecastValueDto = z.object({
  timestamp: z.string().datetime(),
  value: z.number(),
  minValue: z.number().optional().nullable(),
  maxValue: z.number().optional().nullable(),
})

export type ForecastValueDto = z.infer<typeof ForecastValueDto>

export const ForecastDto = z.object({
  tagName: z.string(),
  madeAt: z.string().datetime(),
  prediction: z.array(ForecastValueDto),
})

export type ForecastDto = z.infer<typeof ForecastDto>

export type Forecast = Omit<ForecastDto, 'madeAt' | 'prediction'> & {
  madeAt: Date
  prediction: ForecastPrediction[]
}

export type ForecastPrediction = Omit<ForecastValueDto, 'timestamp'> & {
  timestamp: Date
}

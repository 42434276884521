import { trendTooltipOptions } from 'src/components/ui'
import { ChartData } from '../../useChartData'
import {
  ChartMarkerType,
  ChartOptions,
  LineChartMarker,
  RangeChartMarker,
} from 'src/types/chartTypes'
import { DefaultTheme } from 'styled-components'
import { TimeRange } from 'src/types'
import {
  AxisLabelsFormatterCallbackFunction,
  SeriesArearangeOptions,
  SeriesLineOptions,
  YAxisOptions,
  YAxisPlotBandsOptions,
  YAxisPlotLinesOptions,
} from 'highcharts/highstock'

type MainYAxisOptions = YAxisOptions & {
  data: ChartData[]
  chart: ChartOptions
  theme: DefaultTheme
  hoverIndex?: number
}

export function isMainYAxisOptions(y: YAxisOptions): y is MainYAxisOptions {
  const m = y as MainYAxisOptions
  return m.data !== undefined && m.chart !== undefined && m.theme !== undefined
}

function isLineChartMarker(
  marker: RangeChartMarker | LineChartMarker,
): marker is LineChartMarker {
  return marker.type === ChartMarkerType.LINE
}

function isRangeChartMarker(
  marker: RangeChartMarker | LineChartMarker,
): marker is RangeChartMarker {
  return marker.type === ChartMarkerType.RANGE
}

function lineMarkerPlotLine(marker: LineChartMarker): YAxisPlotLinesOptions {
  return {
    color: marker.color,
    value: marker.value,
    zIndex: 10,
    width: 2,
    dashStyle: 'Dash',
    label: {
      text: marker.label,
      x: 4,
      style: {
        color: marker.color,
      },
    },
  }
}

function rangeMarkerPlotBand(marker: RangeChartMarker): YAxisPlotBandsOptions {
  // Add 10% transparency to the range color
  return {
    color: `${marker.color}1A`,
    from: marker.from,
    zIndex: 10,
    to: marker.to,
    label: {
      text: marker.label,
      verticalAlign: 'top',
      y: -4,
      x: 4,
      style: {
        color: marker.color,
      },
    },
  }
}

type LineOptions = SeriesLineOptions & {
  defaultColor: string
  engUnit: string | undefined
}

// [timestamp, min, max]
type AreaData = [number, number | undefined, number | undefined]
type ArearangeOptions = SeriesArearangeOptions & {
  areaData: AreaData[]
  defaultColor: string
}

type Options = {
  data: ChartData[]
  setTimeRange: (timeRange: TimeRange) => void
  height: number | undefined
  chart: ChartOptions
  theme: DefaultTheme
  formatter: AxisLabelsFormatterCallbackFunction
}

export function trendChartConfig({
  data,
  setTimeRange,
  height,
  chart,
  theme,
  formatter,
}: Options): Highcharts.Options {
  const markers = chart.commonY ? chart.markers ?? [] : []

  const plotBands = markers.filter(isRangeChartMarker).map(rangeMarkerPlotBand)

  const plotLines = [
    ...markers.filter(isLineChartMarker).map(lineMarkerPlotLine),
    // Add border lines for the ranges
    ...markers.filter(isRangeChartMarker).flatMap(m => [
      lineMarkerPlotLine({
        id: `${m.id}from`,
        type: ChartMarkerType.LINE,
        color: m.color,
        value: m.from,
      }),
      lineMarkerPlotLine({
        id: `${m.id}to`,
        type: ChartMarkerType.LINE,
        color: m.color,
        value: m.to,
      }),
    ]),
  ]

  const mainYAxis: MainYAxisOptions = {
    title: { text: undefined },
    lineWidth: 1,
    lineColor: '#e6e6e6',
    min: 0,
    max: 100,
    data,
    chart,
    theme,
    labels: {
      distance: 0,
      formatter: chart.commonY ? undefined : formatter,
      useHTML: true,
    },
    plotBands,
    plotLines,
  }

  const yAxes = chart.commonY
    ? [mainYAxis]
    : [
        mainYAxis,
        ...data.map(({ max, min }) => ({
          title: { text: undefined },
          lineWidth: 1,
          lineColor: '#e6e6e6',
          labels: { enabled: false },
          offset: 0,
          min,
          max,
          startOnTick: false,
          endOnTick: false,
          visible: false,
        })),
      ]

  return {
    chart: {
      marginLeft: 42,
      marginBottom: 20,
      spacingTop: 5,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
      backgroundColor: 'transparent',
      animation: false,
      resetZoomButton: { theme: { display: 'none' } },
      style: { fontFamily: 'Roboto' },
      height,
      zoomType: 'x',
      events: {
        selection: e => {
          e.preventDefault()
          setTimeRange({
            from: e.xAxis[0].min,
            to: e.xAxis[0].max,
          })
          return false
        },
        render() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const chart = this,
            // @ts-ignore
            { plotArea, plotWidth, plotHeight } = chart

          if (plotArea) {
            plotArea.attr({
              width: plotWidth,
              height: plotHeight,
            })
          } else {
            // @ts-ignore
            chart.plotArea = chart.renderer
              .rect(chart.plotLeft, chart.plotTop, plotWidth, plotHeight)
              .attr({
                fill: 'transparent',
                zIndex: 10,
                cursor: 'crosshair',
              })
              .add()
          }
        },
      },
    },
    time: { useUTC: false },
    credits: { enabled: false },
    title: undefined,
    legend: {
      enabled: false,
    },
    loading: {
      style: {
        backgroundColor: 'transparent',
        cursor: 'default',
      },
    },
    xAxis: {
      type: 'datetime',
      minRange: 1,
      lineColor: '#e6e6e6',
    },
    yAxis: yAxes,
    plotOptions: {
      series: {
        animation: false,
        // @ts-ignore `marker: false` is apparently not allowed, but I don't know what it corresponds to??
        // The behaviour of `marker: false` is that a marker is displayed at the closest point to the cursor
        // If we set `marker: undefined` then we get an error
        // If we set `marker: { enabled: false }` then we get no markers at all
        marker: false,
        states: {
          hover: { enabled: false },
          inactive: { opacity: 1 },
        },
      },
    },
    series: data.flatMap((props, index) => {
      const color =
        theme.colors.chart[
          (chart.data[index].colorIndex ?? index + chart.id) %
            theme.colors.chart.length
        ]

      const main = ({ data = [], engUnit }: ChartData): LineOptions => ({
        id: `${chart.id}${index}`,
        yAxis: chart.commonY ? 0 : index + 1,
        type: 'line',
        defaultColor: color,
        color,
        step: 'left',
        data: [...data],
        // dataGrouping: {
        //   endabled: true,
        //   groupPixelWidth: 50,
        // },
        engUnit,
        visible: !chart.data[index].inactive,
      })

      const area = ({ data = [] }: ChartData): ArearangeOptions => {
        const areaData = (props.data || []).some(point => point.length > 2)
          ? data.map<AreaData>(([x, , low, high]) => [x, low, high])
          : []

        return {
          id: `${chart.id}${index}arearange`,
          yAxis: chart.commonY ? 0 : index + 1,
          type: 'arearange',
          areaData,
          data: !chart.data[index].disableRange ? areaData : [],
          defaultColor: color,
          color,
          fillOpacity: 0.1,
          step: 'left',
          // dataGrouping: {
          //   endabled: true,
          //   groupPixelWidth: 50,
          // },
          visible: !chart.data[index].inactive,
        }
      }
      return [main(props), area(props)]
    }),
    tooltip: trendTooltipOptions,
  }
}

import * as api from 'src/services'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { mapApiToDocsLink } from './docs.mappers'
import { FetchDocsLink } from './docs.types'

export function useDocsUrl(): UseQueryResult<FetchDocsLink> {
  return useQuery({
    queryKey: ['docsUrl'],
    queryFn: fetchDocsUrl,
  })
}

async function fetchDocsUrl(): Promise<FetchDocsLink> {
  const res = await api.fetchDocsUrl()
  return mapApiToDocsLink(res.documentationUrl)
}

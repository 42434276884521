import { Text, Card } from 'src/components/ui'
import { FetchAnomalyModel } from 'src/services'
import { useState } from 'react'
import classNames from 'classnames'
import { AnomalyThreshold } from '../AnomalyThresholds'
import { getAnomalyThresholds } from '../../anomaly-detection.utils'

interface AnomalyScoreProps {
  model: FetchAnomalyModel
  open?: boolean
  noDesc?: boolean
}

export function AnomalyScore({
  model,
  noDesc,
}: AnomalyScoreProps): JSX.Element {
  const thresholds = getAnomalyThresholds(model)
  const [thresholdOpen, setThresholdOpen] = useState<number[]>(
    thresholds.map((_, i) => i),
  )

  return (
    <Card className="col-span-2">
      <Text variant="title" bold>
        Anomaly score
      </Text>
      {!noDesc && (
        <Text className="my-m !text-medium">
          The anomaly score is the normalized difference between the prediciton
          and the actual value.
        </Text>
      )}
      {thresholds.map((t, index) => {
        return (
          <AnomalyThreshold
            readOnly
            key={index}
            model={model}
            open={thresholdOpen.includes(index)}
            setOpen={open =>
              setThresholdOpen(
                open
                  ? [...thresholdOpen, index]
                  : thresholdOpen.filter(i => i !== index),
              )
            }
            threshold={t}
            className={classNames(
              'border-solid border-0 border-t border-border',
              index === thresholds.length - 1 && 'border-b',
            )}
          />
        )
      })}
    </Card>
  )
}

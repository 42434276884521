import { useLocation } from 'react-router-dom'
import { useSignalEffect } from '@preact/signals-react'
import { Text, Card, Button, Tooltip } from 'src/components/ui'
import { humanDate, timeAgo } from 'src/utility/time'
import { useUpdateModelMutation } from 'models/api'
import { parse } from 'src/conditions'
import { FetchModel } from 'src/services'
import { DisplayCondition } from 'models/model'
import { hasConfiguredFilterAndThresholds } from 'models/model/model.utils'
import {
  Menu,
  ModelScores,
  ModelDescription,
  ModelTypeContainer,
  Name,
  PropertyContainer,
} from './components'
import {
  initializeModelValues,
  isModelEditing,
  modelValues,
} from './modelOverview.state'

type Props = {
  handleCancel: () => void
  handleSave: () => void
}

function ActionButtons({ handleCancel, handleSave }: Props): JSX.Element {
  return (
    <>
      <Button
        variant="icon-secondary"
        title="Cancel"
        className="!px-xs"
        onClick={handleCancel}
      />
      <Button variant="primary" title="Save" onClick={handleSave} />
    </>
  )
}

interface OverviewProps {
  className?: string
  model: FetchModel
}

export function ModelOverview({
  className,
  model,
}: OverviewProps): JSX.Element {
  const { state } = useLocation()
  const update = useUpdateModelMutation(model)

  useSignalEffect(() => {
    isModelEditing.value = !!state?.edit
    initializeModelValues(model)
  })

  // For anomaly filters
  const tags = [...model.inputTags, model.tag].reduce((acc, tag) => {
    return { ...acc, [tag.tagName]: tag.displayName || tag.tagName }
  }, {} as Record<string, string>)

  async function handleSave(): Promise<void> {
    isModelEditing.value = false
    const updateData = {
      name: modelValues.name.value,
      description: modelValues.description.value,
      typeId: modelValues.typeId.value,
      methodId: modelValues.methodId.value,
      trainingPeriod: modelValues.trainingPeriod.value.period,
      trainingStart: modelValues.trainingPeriod.value.from,
      trainingEnd: modelValues.trainingPeriod.value.to,
    }

    await update.mutateAsync(updateData)
  }

  function handleCancel(): void {
    isModelEditing.value = false
    initializeModelValues(model)
  }

  useSignalEffect(() => {
    const handleUnload = (e: BeforeUnloadEvent): void => {
      if (isModelEditing.value) {
        e.preventDefault()
      }
    }
    if (isModelEditing.value) {
      window.addEventListener('beforeunload', handleUnload)
    }
    return () => {
      window.removeEventListener('beforeunload', handleUnload)
    }
  })

  return (
    <Card className={className}>
      <div className="flex justify-between border-0 border-b border-solid border-border pb-s small:items-center">
        <Name model={model} />
        {isModelEditing.value ? (
          <div className="hidden items-center gap-s medium:flex">
            <ActionButtons
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          </div>
        ) : (
          <Menu model={model} />
        )}
      </div>
      <div className="flex flex-col gap-s pt-s">
        <ModelScores model={model} />
        <ModelTypeContainer model={model} />
        <div className="flex flex-col gap-s whitespace-nowrap small:flex-row small:items-center small:gap-l">
          <PropertyContainer modelType={model.type.name}>
            <Text bold>Creator</Text>
            <Text className="truncate">
              <Tooltip
                direction="bottom-start"
                render={() => <Text>{model.createdBy?.name ?? 'unknown'}</Text>}
              >
                {model.createdBy?.name ?? 'unknown'}
              </Tooltip>
            </Text>
          </PropertyContainer>
          <PropertyContainer modelType={model.type.name}>
            <Text bold>Created</Text>
            <Text>
              {humanDate(model.created)} ({timeAgo(model.created)})
            </Text>
          </PropertyContainer>
        </div>
        {model.__typename === 'AnomalyModel' &&
          hasConfiguredFilterAndThresholds(model.state) && (
            <Text className="my-2xs flex gap-2xs">
              <span className="font-500">Filters:</span>
              {model.anomalyGenerationFilter ? (
                <DisplayCondition
                  containerClassName="flex flex-col gap-2xs"
                  condition={parse(model.anomalyGenerationFilter)}
                  tags={tags}
                />
              ) : (
                <span>Not set</span>
              )}
            </Text>
          )}
        <ModelDescription model={model} />
        {isModelEditing.value && (
          <div className="mt-s flex items-center justify-end gap-s medium:hidden">
            <ActionButtons
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          </div>
        )}
      </div>
    </Card>
  )
}

import { Link } from 'react-router-dom'
import {
  exactDuration,
  humanDateTime,
  timeAgo,
  humanDuration,
} from 'src/utility/time'
import { ANOMALY_STATUS } from 'src/utility/constants/AnomalyConstFinal'
import { useSite } from 'src/contexts/site'
import { SelectInput, Text, Card, EditableField } from 'src/components/ui'
import { useSetAnomalyStateMutation } from 'anomalies/api'
import { Anomaly } from 'src/services'
import { AnomalyLiveTag } from 'anomalies/anomalies'

interface AnomalyOverviewProps {
  anomaly: Anomaly
  className?: string
}

export function Overview({
  anomaly,
  className,
}: AnomalyOverviewProps): JSX.Element {
  const { rootLink } = useSite()
  const mutation = useSetAnomalyStateMutation()

  return (
    <Card className={className}>
      <Text variant="title" bold className="mb-[0.3em] !text-2xl">
        Anomaly {anomaly.id}
      </Text>
      <AnomalyLiveTag
        inProgress={anomaly.inProgress}
        className="max-w-[100px]"
      />
      <div>
        <Text className="my-xs">
          Model:{' '}
          <Link to={`${rootLink}/models/${anomaly.model.id}`}>
            {anomaly.model.name}
          </Link>
        </Text>
      </div>
      <Text className="my-xs">
        Start time: {humanDateTime(anomaly.start)} ({timeAgo(anomaly.start)})
      </Text>
      {anomaly.end && (
        <Text className="my-xs">
          Duration:{' '}
          <span title={exactDuration(anomaly.start, anomaly.end)}>
            {humanDuration(anomaly.start, anomaly.end)}
          </span>
        </Text>
      )}
      <Text className="my-xs">
        Peak score: {anomaly.score ? anomaly.score.toFixed(4) : '-'}
      </Text>
      <EditableField
        initialState={
          ANOMALY_STATUS.find(({ value }) => value === anomaly.state) ??
          ANOMALY_STATUS[0]
        }
        renderDisplay={() => (
          <Text>Status: {anomaly.state.replace('_', ' ')}</Text>
        )}
        renderInput={({ state, setState }) => (
          <>
            <Text>Status:</Text>
            <div className="w-[190px]">
              <SelectInput
                value={state}
                options={ANOMALY_STATUS}
                onChange={value => {
                  const newValue = ANOMALY_STATUS.find(el => el.value === value)
                  if (newValue) setState(newValue)
                }}
              />
            </div>
          </>
        )}
        updateStatus={mutation.status}
        onSave={async state => {
          await mutation.mutateAsync({
            anomalyId: anomaly.id,
            state: state.value,
          })
        }}
        saveDisabled={state => state?.value === anomaly.state}
        isEditable
      />
    </Card>
  )
}

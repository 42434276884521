import { useEffect } from 'react'
import { TimeRangeProvider } from 'src/contexts/timeRange'
import { CursorProvider } from 'src/contexts/cursor'
import { FilterProvider } from 'src/contexts/filter'
import { ModelAnomaliesProvider } from 'src/contexts/modelAnomalies'
import { useModel } from 'models/api'
import { useParams } from 'react-router-dom'
import { useSite } from 'src/contexts/site'
import { useTitle } from 'src/utility'
import { Text, Spinner, Card } from 'src/components/ui'
import toast from 'react-hot-toast'
import { add, sub } from 'date-fns'
import { metropolisDataRange } from 'src/utility/metropolis'
import { FetchModel } from 'src/services'
import { AnomalyDetectionModel, ForecastModel } from './variants'
import {
  AWAITING_ANOMALY_ANALYSIS,
  hasRunInitialInference,
} from './model.utils'

const offset = (model: FetchModel): number => {
  switch (model.method.name) {
    case 'Forecast 1H':
      return 60
    case 'Forecast 2H':
      return 120
    case 'Forecast 4H':
      return 240
    default:
      return 0
  }
}

const defaultToTime = (model: FetchModel): Date => {
  if (
    model.__typename === 'AnomalyModel' &&
    model.state === AWAITING_ANOMALY_ANALYSIS
  ) {
    const ts = model.initialInferenceEnd || model.activeTrainedModel?.dataEnd
    return ts ? ts : new Date()
  }

  if (model.type.name === 'Forecast' && hasRunInitialInference(model.state)) {
    return add(new Date(), { minutes: offset(model) })
  }

  return new Date()
}

export function ModelPage(): JSX.Element | null {
  useTitle('Model')
  const { modelId } = useParams()
  if (!modelId) throw new Error('`modelId` route param missing')
  const { data: model, isLoading: isModelPending } = useModel(modelId)
  const { isWorkshop } = useSite()

  useEffect(() => {
    if (!isModelPending && !model) {
      toast.error(`Model ${modelId} Not Found`)
    }
  }, [modelId, isModelPending, model])

  // if the model is loading, then return immediately
  if (isModelPending) {
    return (
      <div className="m-[1em] mt-0">
        <Card>
          <Text variant="title" bold>
            Model
          </Text>
          <Spinner />
        </Card>
      </div>
    )
  }

  // if the model is not pending and is still undefined then
  // it doesn't exist.
  // FIXME: we should just display something here and get rid of the
  // toast
  if (!model) return null

  // we now have a valid model. Work out the time default range

  const defaultTo = defaultToTime(model)
  const defaultTimeRange = isWorkshop
    ? metropolisDataRange
    : {
        from:
          model.state === AWAITING_ANOMALY_ANALYSIS
            ? model.initialInferenceStart
              ? model.initialInferenceStart.valueOf()
              : sub(defaultTo, { months: 6 }).valueOf()
            : sub(defaultTo, { days: 1 }).valueOf(),
        to: defaultTo.valueOf(),
      }

  return (
    <div className="m-[1em] mt-0">
      <TimeRangeProvider urlQuery defaultTimeRange={defaultTimeRange}>
        <CursorProvider>
          {model.__typename === 'ForecastModel' ? (
            <ForecastModel model={model} />
          ) : (
            <FilterProvider condition={model.anomalyGenerationFilter}>
              <ModelAnomaliesProvider modelId={modelId}>
                <AnomalyDetectionModel model={model} />
              </ModelAnomaliesProvider>
            </FilterProvider>
          )}
        </CursorProvider>
      </TimeRangeProvider>
    </div>
  )
}

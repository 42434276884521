import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import {
  OrganizationStatus,
  OrganizationUser,
  OrganizationRole,
} from 'src/types'
import { Icon, TableConfig, Text, Tooltip } from 'src/components/ui'
import { HomeOrgField } from 'orgs-sites/org'
import { OrganizationRoleSelector } from './components '

interface OrgUsersTableConfigActions {
  canDeactivateUser: (userOrgId: string) => boolean
  openDeactivateUserModal: (id: string) => void
  canRemoveUser: (userOrgId: string) => boolean
  openRemoveUserModal: (id: string) => void
  openEnableUserModal: (id: string) => void
  currentUser?: OrganizationUser
  orgId: string
  onRoleChange: (role: OrganizationRole, userId: string) => Promise<void>
}

export const orgUsersTableConfig = ({
  canDeactivateUser,
  openDeactivateUserModal,
  canRemoveUser,
  openRemoveUserModal,
  openEnableUserModal,
  currentUser,
  orgId,
  onRoleChange,
}: OrgUsersTableConfigActions): TableConfig<OrganizationUser> => {
  return {
    pageSize: 50,
    pageSizes: [10, 50, 100],
    allowPaging: true,
    allowSorting: true,
    columns: [
      {
        field: 'homeOrganization.name',
        headerName: 'Home Organization',
        flex: 200,
        cellRenderer: ({ data: { homeOrganization } }: any) => {
          return (
            <HomeOrgField homeOrganization={homeOrganization} orgId={orgId} />
          )
        },
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 200,
        cellRenderer: ({ value }: any) => (
          <Text variant="content" bold>
            {value || (
              <span className="text-text-tertiary">Not registered yet</span>
            )}
          </Text>
        ),
      },
      {
        field: 'email',
        headerName: 'Email Address',
        flex: 200,
        cellRenderer: ({ value }: any) => (
          <Text variant="content">{value}</Text>
        ),
      },
      {
        field: 'role',
        headerName: 'Organization Role',
        flex: 220,
        minWidth: 220,
        cellClass: 'selector-cell',
        cellRenderer: ({ data: { role, id } }: any) => {
          return (
            <OrganizationRoleSelector
              role={role}
              userId={id}
              handleSaveRole={onRoleChange}
              currentUser={currentUser}
            />
          )
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 200,
        cellRenderer: ({ data: { status, inviteDaysLeft } }: any) => (
          <Text
            variant="content"
            bold
            className={classNames(
              status === OrganizationStatus.PENDING && 'text-text-tertiary',
              status === OrganizationStatus.INACTIVE && 'text-text-danger',
            )}
          >
            {`${status}${
              inviteDaysLeft
                ? ` (${inviteDaysLeft} ${
                    inviteDaysLeft === 1 ? 'day' : 'days'
                  } left)`
                : ''
            }`}
          </Text>
        ),
      },
      {
        headerName: 'Actions',
        // colAllowFiltering: false,
        menuTabs: [],
        sortable: false,
        cellRenderer: ({ data: { status, id, homeOrganization } }: any) => (
          <>
            {id !== currentUser?.id &&
              status !== OrganizationStatus.INACTIVE && (
                <div className="flex h-full items-center">
                  {canDeactivateUser(homeOrganization.id) && (
                    <Tooltip
                      render={() => 'Deactivate user'}
                      direction="bottom"
                    >
                      <div className="flex h-full items-center">
                        <div
                          onClick={() => openDeactivateUserModal(id)}
                          className="cursor-pointer"
                        >
                          <Icon
                            size="big"
                            icon={light('ban')}
                            className="text-text-danger"
                          />
                        </div>
                      </div>
                    </Tooltip>
                  )}
                  {canRemoveUser(homeOrganization.id) && (
                    <Tooltip render={() => 'Remove user'} direction="bottom">
                      <div className="flex h-full items-center">
                        <div
                          onClick={() => openRemoveUserModal(id)}
                          className="cursor-pointer"
                        >
                          <Icon
                            size="big"
                            icon={light('circle-xmark')}
                            className="text-text-danger"
                          />
                        </div>
                      </div>
                    </Tooltip>
                  )}
                </div>
              )}

            {id !== currentUser?.id &&
              status === OrganizationStatus.INACTIVE &&
              canDeactivateUser(homeOrganization.id) && (
                <Tooltip render={() => 'Reactivate user'} direction="bottom">
                  <div className="flex h-full items-center">
                    <div
                      onClick={() => openEnableUserModal(id)}
                      className="cursor-pointer"
                    >
                      <Icon size="big" icon={light('rotate-right')} />
                    </div>
                  </div>
                </Tooltip>
              )}
          </>
        ),
      },
    ],
  }
}

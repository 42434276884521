import Highlighter from 'react-highlight-words'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Link } from 'react-router-dom'
import { Icon, Text, TableConfig, getBaseFilterParams } from 'src/components/ui'
import { ModelState } from 'pages/site/models'
import { AnomalyDuration, AnomalyLiveTag, AnomalyTime } from './components'

export const getAnomalyTableConfig = (filter = ''): TableConfig<any> => {
  return {
    allowPaging: true,
    pageSize: 20,
    pageSizes: [20, 50, 100, 200],
    allowSorting: true,
    allowSearch: false,
    columns: [
      {
        field: 'inProgress',
        headerName: 'Live',
        minWidth: 100,
        flex: 100,
        menuTabs: [],
        cellRenderer: ({ value }: any) => {
          return <AnomalyLiveTag inProgress={value} />
        },
      },
      {
        field: 'modelName',
        headerName: 'Model name',
        minWidth: 400,
        filterParams: getBaseFilterParams(true),
        cellRenderer: ({ data: { modelName, tagName, tag, link } }: any) => (
          <Link
            to={link}
            className="flex h-full w-full flex-col text-inherit no-underline"
          >
            <Text>
              <Highlighter
                searchWords={filter.split(' ')}
                textToHighlight={modelName ?? ''}
                autoEscape
              />
            </Text>
            <Text className="text-text-tertiary">
              Output tag:{' '}
              <Highlighter
                searchWords={filter.split(' ')}
                textToHighlight={tag?.displayName || tagName}
                autoEscape
              />
            </Text>
          </Link>
        ),
      },
      {
        field: 'modelState',
        headerName: 'Model state',
        minWidth: 130,
        flex: 150,
        cellRenderer: ({ data: { modelState } }: any) => (
          <ModelState hideBackground className="w-full" state={modelState}>
            <Text>
              <Highlighter
                searchWords={filter.split(' ')}
                textToHighlight={modelState || ''}
                autoEscape
              />
            </Text>
          </ModelState>
        ),
      },
      {
        field: 'from',
        headerName: 'Start time',
        minWidth: 200,
        flex: 200,
        filter: '',
        cellRenderer: ({ value }: any) => <AnomalyTime time={value} />,
      },
      {
        field: 'duration',
        headerName: 'Duration',
        minWidth: 100,
        flex: 150,
        filter: '',
        cellRenderer: ({ data }: any) => <AnomalyDuration anomaly={data} />,
      },
      {
        field: 'score',
        headerName: 'Peak score',
        minWidth: 100,
        flex: 120,
        cellRenderer: ({ value }: any) => (
          <Text>{typeof value === 'number' ? value.toFixed(4) : ''}</Text>
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 100,
        flex: 120,
        cellRenderer: ({ value }: any) => (
          <Text>
            <Highlighter
              searchWords={filter.split(' ')}
              textToHighlight={value ?? ''}
              autoEscape
            />
          </Text>
        ),
      },
      {
        field: 'commentsCount',
        headerName: 'Comments',
        cellRenderer: ({ value }: any) =>
          value > 0 ? (
            <div className="grid grid-flow-col items-center justify-end gap-[0.2em]">
              <Text>{value}</Text>
              <Icon icon={light('comment')} />
            </div>
          ) : null,
      },
    ],
  }
}

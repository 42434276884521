import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { Anomaly, fetchAnomaly as fetchAnomalyRequest } from 'src/services'

export const ANOMALY = 'anomaly'

export function useAnomaly(
  factory: string,
  anomaly: string,
): UseQueryResult<Anomaly, Error> {
  return useQuery({
    queryKey: [ANOMALY, anomaly],
    queryFn: async () => {
      return fetchAnomaly(factory, anomaly)
    },
  })
}

async function fetchAnomaly(
  factory: string,
  anomalyId: string,
): Promise<Anomaly> {
  const anomaly = await fetchAnomalyRequest(factory, anomalyId)
  if (!anomaly) {
    throw new Error('Anomaly not found')
  }
  return anomaly
}

import { Signal, signal } from '@preact/signals-react'
import { FetchModel } from 'src/services'

type TrainingPeriod = {
  from?: number
  to?: number
  period?: string
}

type ModelValues = {
  name: Signal<string>
  description: Signal<string>
  typeId: Signal<string>
  methodId: Signal<string>
  trainingPeriod: Signal<TrainingPeriod>
}

export const isModelEditing = signal(false)

export const modelValues: ModelValues = {
  name: signal(''),
  description: signal(''),
  typeId: signal(''),
  methodId: signal(''),
  trainingPeriod: signal({ period: 'P1Y' }),
}

export function initializeModelValues(model: FetchModel): void {
  const getTrainingPeriod = (): any =>
    model.trainingStart && model.trainingEnd
      ? {
          from: model.trainingStart.valueOf(),
          to: model.trainingEnd.valueOf(),
        }
      : { period: model.trainingPeriod || 'P1Y' }

  modelValues.name.value = model.name
  modelValues.description.value = model.description
  modelValues.typeId.value = model.type.id
  modelValues.methodId.value = model.method.id
  modelValues.trainingPeriod.value = getTrainingPeriod()
}

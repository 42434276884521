import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Icon } from '../../icons'
import { Text } from '../../typography'
import classNames from 'classnames'
import { useEffect, useState } from 'react'

type TextInputProps = {
  variant?: 'outlined' | 'underlined'
  label?: string
  value?: number
  onChange: (val: number | undefined) => void
  placeholder?: string
  iconRight?: IconProp
  iconLeft?: IconProp
  onIconRightClick?: () => void
  onIconLeftClick?: () => void
  disabled?: boolean
  className?: string
  containerStyles?: string
  inputStyles?: string
  min?: number
  max?: number
  step?: number
  allowUndefined?: boolean
  error?: boolean
}

const TextInput = ({
  variant = 'outlined',
  placeholder,
  label,
  value,
  onChange,
  iconRight,
  iconLeft,
  onIconRightClick,
  onIconLeftClick,
  disabled,
  className,
  containerStyles,
  inputStyles,
  min,
  max,
  step,
  allowUndefined = false,
  error,
}: TextInputProps): JSX.Element => {
  const [val, setVal] = useState(value?.toString() || '')

  useEffect(() => {
    setVal(value?.toString() || '')
  }, [value])

  return (
    <div className={classNames('flex flex-col gap-2xs', className)}>
      {label && (
        <label>
          <Text variant="small" bold>
            {label}
          </Text>
        </label>
      )}
      <div
        className={classNames(
          `flex items-center justify-between gap-2xs w-full ${
            variant === 'outlined'
              ? `rounded-2xs border border-solid bg-background p-xs ${
                  error ? 'border-border-danger' : 'border-border'
                }`
              : `border-0 border-b border-solid py-xs ${
                  error ? 'border-border-danger' : 'border-border-secondary'
                }`
          }`,
          containerStyles,
        )}
      >
        {iconLeft && (
          <Icon
            icon={iconLeft}
            onClick={onIconLeftClick}
            className={`cursor-pointer ${disabled && 'text-icon-secondary'}`}
          />
        )}
        <input
          className={classNames(
            'flex w-full min-w-0 appearance-none border-none bg-transparent text-description font-500 disabled:text-text-disabled',
            inputStyles,
          )}
          value={val}
          type="number"
          placeholder={placeholder}
          min={min}
          max={max}
          step={step}
          disabled={disabled}
          onChange={e => {
            setVal(e.target.value)
            if (e.target.value === '' && allowUndefined) {
              onChange(undefined)
            } else if (!isNaN(e.target.valueAsNumber)) {
              onChange(e.target.valueAsNumber)
            }
          }}
          onKeyDown={e => {
            if (
              !e.key.match(/^[0-9,.-]+$/) &&
              e.key !== 'Backspace' &&
              e.key !== 'Delete' &&
              e.key !== 'ArrowUp' &&
              e.key !== 'ArrowDown' &&
              e.key !== 'ArrowLeft' &&
              e.key !== 'ArrowRight' &&
              e.key !== 'Tab'
            ) {
              e.preventDefault()
            }
          }}
        />
        {iconRight && (
          <Icon
            icon={iconRight}
            onClick={onIconRightClick}
            className={`cursor-pointer ${disabled && 'text-icon-secondary'}`}
          />
        )}
      </div>
    </div>
  )
}
export default TextInput

import { Icon, Text } from 'src/components/ui'
import { useWorkspaces } from './api'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { WorkspaceCard } from './components/WorkspaceCard'

export function NewDataExplorerPage(): JSX.Element {
  const workspacesQuery = useWorkspaces()
  console.log(workspacesQuery.data)
  return (
    <div className="m-s mt-0 flex h-[calc(100%-1em)] flex-col rounded-2xs bg-white p-m">
      <div className="mb-s flex w-full justify-start gap-l pb-s pl-xs">
        <Icon
          icon={light('magnifying-glass-chart')}
          className="!h-[74px] !w-[93px] text-icon-secondary"
        />
        <div>
          <Text variant="description" bold>
            Workspaces
          </Text>
          <Text variant="content" className="!mt-2xs mb-xs">
            A <span className="font-500">workspace</span> is a collection of
            analyses giving insight into your processes and data.
          </Text>
        </div>
      </div>
      <hr className="w-full h-px" />
      <div className="flex w-full flex-wrap items-center justify-start gap-s pt-s">
        {workspacesQuery.data?.map(workspace => (
          <WorkspaceCard key={workspace.id} workspace={workspace} />
        ))}
      </div>
    </div>
  )
}

import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import MenuWrapper from 'react-popper-tooltip'
import { Icon, Text } from 'src/components/ui'
import { ChartOptions } from 'src/types/chartTypes'
import { useTheme } from 'styled-components'
import classNames from 'classnames'
import { zIndex } from 'src/utility/constants/StyleConstants'

interface MenuProps {
  chart: ChartOptions
  setChart?: (options: ChartOptions) => void
  isModal?: boolean
}
export default function GraphSettings({
  chart,
  setChart,
  isModal,
}: MenuProps): JSX.Element {
  const theme = useTheme()

  const colorIndex = chart.colorIndex ?? chart.id
  return (
    <MenuWrapper
      placement="left"
      trigger="click"
      tooltip={({ tooltipRef: ref, getTooltipProps }) => (
        <div
          className="max-h-[350px] overflow-y-auto rounded-2xs border border-solid border-border bg-background px-m py-xs shadow-lg"
          {...getTooltipProps({
            ref,
            style: {
              zIndex: isModal ? zIndex.modalLegendMenu : zIndex.trendLegendMenu,
            },
          })}
        >
          <Text variant="description" bold>
            Chart Settings
          </Text>
          <div className="flex items-center gap-2xs py-xs">
            {theme.colors.chart.map((color, i) => (
              <div
                key={i}
                onClick={e => {
                  e.preventDefault()
                  return setChart && setChart({ ...chart, colorIndex: i })
                }}
                className={classNames(
                  'aspect-square cursor-pointer rounded-full relative',
                  colorIndex === i
                    ? 'w-[8px] border border-solid m-[3px]'
                    : 'w-[14px]',
                )}
                style={{ backgroundColor: color, borderColor: color }}
              >
                <div
                  className={`absolute left-[50%] top-[50%] aspect-square w-[14px] translate-x-[-50%] translate-y-[-50%] rounded-full border border-solid ${
                    colorIndex === i ? 'block' : 'hidden'
                  }`}
                  style={{ borderColor: color }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    >
      {({ getTriggerProps, triggerRef: ref }) => (
        <div
          className="flex w-[100px] cursor-pointer items-center gap-2xs leading-[0]"
          {...getTriggerProps({ ref })}
        >
          <Icon icon={light('gear')} />
          <Text variant="description" bold>
            Chart Settings
          </Text>
        </div>
      )}
    </MenuWrapper>
  )
}

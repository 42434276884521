import { useRef } from 'react'
import { Field, Form, Formik, FormikProps } from 'formik'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import moment from 'moment'
import * as Yup from 'yup'
import classNames from 'classnames'
import { Button, Checkbox, Icon, Modal, Text, Tooltip } from 'src/components/ui'
import { zIndex } from 'src/utility/constants/StyleConstants'
import { GqlFactoryTrendViewFragment } from 'src/services'
import { humanDuration } from 'src/utility/time'
import { useCharts } from 'src/contexts/charts'
import { useFactoryTrendViews } from 'trend/api'

type Props = {
  siteName: string
  view?: GqlFactoryTrendViewFragment
  isViewChanged?: boolean
  isModalOpen: boolean
  onClose: () => void
  onSaveAs: ({ name, shared }: FormValues) => void
  onSave: ({ name, shared }: FormValues) => void
  canEdit: boolean
  currentUserId: string
}

type FormValues = {
  name: string
  shared: boolean
}

const FormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  discoverable: Yup.boolean(),
})

export function SaveTrendViewModal({
  siteName,
  view,
  isModalOpen,
  onClose,
  onSaveAs,
  onSave,
  canEdit,
  currentUserId,
}: Props): JSX.Element {
  const { data: trendViews } = useFactoryTrendViews()
  const formikRef = useRef<FormikProps<FormValues>>(null)
  const { isViewChanged } = useCharts()

  function handleSubmit({ name, shared }: FormValues): void {
    if (name !== view?.name || (view && currentUserId !== view.user.id)) {
      onSaveAs({ name, shared })
    } else {
      onSave({ name, shared })
    }
    onClose()
  }

  function validateName(name: string): string | undefined {
    if (name === view?.name && currentUserId === view?.user.id) return undefined
    const isNameExists = trendViews?.some(view => view.name === name)
    if (isNameExists) {
      return 'There is already a view with this name.'
    }
    return undefined
  }

  return (
    <Formik
      initialValues={{
        name: view && currentUserId === view.user.id ? view?.name : '',
        shared: view && currentUserId === view.user.id ? view?.shared : false,
      }}
      validationSchema={FormSchema}
      onSubmit={handleSubmit}
      innerRef={formikRef}
    >
      {({ errors, isValid, values, setValues }) => (
        <Modal
          title="Save view"
          isOpen={isModalOpen}
          close={onClose}
          footer={
            <div className="flex w-full items-center justify-end pt-s">
              <div className="flex items-center gap-xs">
                <Button
                  variant="secondary"
                  onClick={onClose}
                  title="Cancel"
                  className="!py-[7px]"
                />

                <Tooltip
                  render={() => (
                    <Text variant="description">No changes have been made</Text>
                  )}
                  direction="bottom"
                  zIndex={zIndex.modalLegendMenu}
                  isOpen={
                    view &&
                    !isViewChanged &&
                    view.name === values.name &&
                    view.shared === values.shared
                      ? undefined
                      : false
                  }
                >
                  <Button
                    variant="primary"
                    title="Save"
                    onClick={() => handleSubmit(values)}
                    disabled={
                      !isValid ||
                      (!canEdit && view?.name === values.name) ||
                      !values.name ||
                      (view &&
                        !isViewChanged &&
                        view.name === values.name &&
                        view.shared === values.shared)
                    }
                  />
                </Tooltip>
              </div>
            </div>
          }
          closeIconSize="regular"
        >
          <>
            <Form className="mb-s max-w-[320px]">
              <Text className="mt-xs">
                {view && view.user.id !== currentUserId
                  ? `The view you have edited is owned by ${view.user.name}. To save your changes, you can make your own copy of the view.`
                  : 'Save your trend view settings for quick access later, or switch effortlessly between saved views.'}
              </Text>
              <div className="relative my-m">
                <label className="mb-xs block" htmlFor="name">
                  <Text
                    variant="description"
                    bold
                    className={classNames(
                      'my-xs',
                      errors.name && 'text-text-danger',
                    )}
                  >
                    Name
                  </Text>
                </label>
                {view && (
                  <Text
                    variant="small"
                    bold
                    className="absolute right-0 top-2xs"
                  >
                    <span className="font-normal text-text-tertiary">
                      Last update:{' '}
                    </span>
                    {humanDuration(moment.now(), moment(view.updatedAt))} ago
                  </Text>
                )}
                <Field
                  validate={validateName}
                  name="name"
                  placeholder="Name your view"
                  className={classNames(
                    'flex w-full items-center rounded-2xs border-none pl-xs text-xs font-medium leading-[32px] outline-none ring-1 ring-offset-1 placeholder:font-thin',
                    errors.name
                      ? 'bg-background-danger-tertiary-hover ring-border-danger text-text-danger'
                      : 'ring-grey-disabled',
                  )}
                ></Field>
                {!errors.name ? (
                  <Text
                    variant="small"
                    className="absolute -bottom-icon-big left-0 text-text-tertiary"
                  >
                    Give a name to your new view
                  </Text>
                ) : (
                  <Text
                    variant="small"
                    className="absolute -bottom-icon-big left-0 text-text-danger"
                  >
                    {errors.name ? errors.name : null}
                  </Text>
                )}
              </div>
              <div className="relative mt-l">
                <div className="flex items-center gap-xs">
                  <Checkbox
                    value={values.shared}
                    onChange={value =>
                      setValues({ name: values.name, shared: value })
                    }
                  />
                  <Text variant="small" bold>
                    Make this view discoverable
                  </Text>
                  <Tooltip
                    zIndex={zIndex.modalLegendMenu}
                    render={() => (
                      <Text variant="description" className="max-w-[200px]">
                        When a view is{' '}
                        <span className="font-500">Discoverable</span>, anyone
                        that has access to{' '}
                        <span className="font-500">{siteName}</span> will be
                        able to visualise it in the{' '}
                        <span className="font-500">Saved Views</span> panel.
                      </Text>
                    )}
                    direction="bottom"
                  >
                    <Icon
                      icon={light('circle-question')}
                      size="small"
                      className="cursor-pointer"
                    />
                  </Tooltip>
                </div>
              </div>
            </Form>
          </>
        </Modal>
      )}
    </Formik>
  )
}

import { useEffect, useRef, useState } from 'react'
import { TimeRangeOrPeriod, isTimeRange, TimeRange } from 'src/types'
import { Icon, Text } from '../..'
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { rangeSummary } from './rangeSummary'
import { CustomDateTimeRangeModal } from './CustomDateTimeRangeModal'
import { format } from 'date-fns'
import { metropolisDataRange } from 'src/utility/metropolis'

export type LabelValue = {
  label: string
  summaryLabel?: string
  value: string
}

export enum DateTimeType {
  From = 'from',
  To = 'to',
}

const humanDateRange = ({ from, to }: TimeRange): JSX.Element => {
  return (
    <Text className="whitespace-nowrap">
      <span className="font-500">{format(from, 'MMM dd yyyy, ')}</span>
      {format(from, 'HH:mm')} -{' '}
      <span className="font-500">{format(to, 'MMM dd yyyy, ')}</span>
      {format(to, 'HH:mm')}
    </Text>
  )
}

interface TimePickerInputProps {
  value: TimeRangeOrPeriod
  isLive: boolean
  options?: LabelValue[]
  onChange: (value: TimeRangeOrPeriod) => void
  allowCustomRange?: boolean
  maxDate?: Date
  position?: 'left' | 'right'
  showTimeOption?: boolean
}

const defaultOptions: LabelValue[] = [
  { label: 'Last 12 months', value: 'P1Y', summaryLabel: '12mo' },
  { label: 'Last 6 months', value: 'P6M', summaryLabel: '6mo' },
]

export function TimePickerInput({
  value,
  isLive,
  options = defaultOptions,
  onChange,
  allowCustomRange = true,
  maxDate,
  position = 'right',
  showTimeOption = true,
}: TimePickerInputProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const [isCustomOpen, setIsCustomOpen] = useState(false)
  const customRef = useRef<HTMLDivElement>(null)
  const customDateRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  /*
   * Workaround for React-calendar:
   * When a month/year is selected, React-calendar removes the event target from the DOM.
   * Therefore, this workaround is necessary to prevent the dropdown from closing unintentionally.
   */
  useEffect(() => {
    const handleClick = (e: any): void => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        const element = e.target as HTMLElement
        const className =
          typeof element.className === 'string' ? element.className : ''
        if (
          element.tagName !== 'ABBR' &&
          !className.split(' ').includes('react-calendar__tile')
        ) {
          if (isOpen) setIsOpen(false)
        }
      }
    }
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [dropdownRef, isOpen])

  const selectLabel = isTimeRange(value) ? (
    isLive ? (
      <Text className="whitespace-nowrap" bold>
        {rangeSummary(value, options).rangeDescription}
      </Text>
    ) : (
      humanDateRange(value)
    )
  ) : (
    <Text bold className="whitespace-nowrap">
      {options.find(option => option.value === value.period)?.label}
    </Text>
  )

  return (
    <div
      className={`relative flex w-[350px] cursor-pointer items-center justify-between gap-s rounded-2xs border border-solid bg-background px-xs py-[5px] transition duration-300 ${
        isOpen ? 'border-border-brand' : 'border-border'
      }`}
      onClick={e => {
        if (
          !customRef.current ||
          (!customRef.current.contains(e.target as Node) &&
            !customDateRef.current?.contains(e.target as Node))
        ) {
          setIsOpen(!isOpen)
          setIsCustomOpen(false)
        }
      }}
    >
      <div className="flex items-center gap-xs">
        <div
          className={`w-[42px] rounded-2xs px-2xs py-3xs text-center ${
            isLive ? 'bg-background-brand' : 'bg-background-brand-hover'
          }`}
        >
          <Text variant="description">
            {isLive ? 'Live' : rangeSummary(value, options).rangeSummary}
          </Text>
        </div>
        {selectLabel}
      </div>
      <Icon
        icon={light('chevron-down')}
        className={`transition duration-300 ${isOpen && 'rotate-180'}`}
      />
      {isOpen && (
        <div
          className={`absolute top-[calc(100%+4px)] z-20 flex rounded-2xs border border-solid border-border bg-background drop-shadow-dropdown ${
            position === 'left' ? 'left-0' : 'right-0'
          }`}
          ref={dropdownRef}
        >
          <div className="pt-xs">
            {options.map(option => (
              <div
                key={option.value}
                className="flex cursor-pointer items-center gap-xs whitespace-nowrap px-xs py-2xs transition-all hover:bg-background-hover"
                onClick={() => {
                  if (option.value === 'metropolis') {
                    onChange(metropolisDataRange)
                  } else {
                    onChange({ period: option.value })
                  }
                }}
              >
                {option.summaryLabel && (
                  <>
                    {option.value === 'metropolis' ? (
                      <div className="flex h-[20px] w-[42px] items-center justify-center rounded-2xs bg-background-brand-hover px-2xs text-center ">
                        <Icon
                          size="xsmall"
                          icon={solid('star')}
                          className="size-[12px] text-yellow-500"
                        />
                      </div>
                    ) : (
                      <div className="w-[42px] rounded-2xs bg-background-brand-hover px-2xs py-3xs text-center ">
                        <Text variant="description">{option.summaryLabel}</Text>
                      </div>
                    )}
                  </>
                )}
                <Text
                  variant="description"
                  className="whitespace-nowrap py-3xs"
                >
                  {option.label}
                </Text>
              </div>
            ))}
            {allowCustomRange && (
              <div
                ref={customRef}
                className="mt-[12px] flex items-center gap-m border-0 border-t border-solid border-border p-xs transition-all hover:bg-background-hover"
                onClick={() => setIsCustomOpen(!isCustomOpen)}
              >
                <Text variant="description" bold className="whitespace-nowrap">
                  Select custom range
                </Text>
                <Icon
                  icon={light('chevron-right')}
                  className={`transition duration-200 ${
                    isCustomOpen ? 'rotate-180 text-text-brand' : ''
                  }`}
                />
              </div>
            )}
          </div>
          {isCustomOpen && (
            <div ref={customDateRef}>
              <CustomDateTimeRangeModal
                onChange={onChange}
                onClose={() => setIsOpen(false)}
                maxDate={maxDate}
                showTimeOption={showTimeOption}
                timeRange={isTimeRange(value) ? value : undefined}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

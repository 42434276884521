import { useLocation } from 'react-router-dom'
import { Text } from 'src/components/ui'
import { FactoryPicker } from './FactoryPicker'
import { UserMenu } from 'pages/settings'
import { useNavigationContext } from 'src/contexts/navigation'

const titles: {
  [key: string]: string
} = {
  'data-explorer': 'Data Explorer',
  'data-explorer-new': 'Data Explorer',
  labs: 'Labs',
  models: 'Models',
  trend: 'Trend',
  'trend-beta': 'Trend',
  forecast: 'Forecast',
  anomalies: 'Anomalies',
  assets: 'Assets',
  tag: 'Tag details',
}

export function TopNavigation({
  currentSiteId,
}: {
  currentSiteId: string
}): JSX.Element {
  // FIXME: this is crazy
  const { pathname } = useLocation()
  const [, , , pagePart] = pathname.split('/')
  const { titleComponent } = useNavigationContext()
  const title = titleComponent ?? (
    <Text variant="title" bold>
      {titles[pagePart]}
    </Text>
  )

  return (
    <div className="flex items-center justify-between px-s pt-xs">
      <div>{title}</div>
      <div className="flex items-center justify-center gap-s">
        <FactoryPicker currentSiteId={currentSiteId} />
        <UserMenu />
      </div>
    </div>
  )
}

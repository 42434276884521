import { GqlDocumentationUrlFragment } from 'src/services'
import { FetchDocsLink } from './docs.types'
import { parseISO } from 'date-fns'

export function mapApiToDocsLink(
  documentationUrl: GqlDocumentationUrlFragment,
): FetchDocsLink {
  return {
    ...documentationUrl,
    expiry: parseISO(documentationUrl.expiry),
  }
}
